import classNames from "classnames";

export default function Pagination({ meta, setPage, page }: any) {
    return (
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{meta?.page} </span> to <span className="font-medium">{meta?.limit}</span> of{' '}
            <span className="font-medium">{meta?.totalData}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
         {page == 1 && meta?.page == 1  ? null : (
            <button
               className={classNames("relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50")}
               onClick={() => setPage((prev: any) => prev - 1)}
             >
               Previous
             </button> 
         )}
         {page < meta?.totalPage ? (
             <button
             className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
             onClick={() => setPage((prev: any) => prev + 1)}
           >
             Next
           </button>
         ): null} 
        </div>
      </nav>
    )
  }
  