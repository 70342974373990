import { Field, Formik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useAssesments } from '../../../../hooks/assessments';
import { useQuestionDetails } from '../../../../hooks/questions';
import Button from '../../../../ui/Button';
import Card from '../../../../ui/Card';
import InputBox from '../../../../ui/InputBox';
import Spinner from '../../../../ui/Spinner';
import SwitchToggle from '../../../../ui/SwitchToggle';
import { CodingChallengeQuestionOptions } from '../CreateCodingChallenges';
import { DifficultyLevel, McqQuestionOptions } from '../CreateMCQ';
import { updateQuestion } from '../../../../services/questions';
import Notification, { notifySuccess } from '../../../../ui/Notificaiton';
import Editor from "@monaco-editor/react";
import classNames from 'classnames';
import { TailSpin } from 'react-loader-spinner';
import TextEditor from '../../../../ui/text-editor/TextEditor';
import { useDomains } from '../../../../hooks/domains';
import { useSkills } from '../../../../hooks/skills';
import { SelectField } from '../../../../ui/SelectField';

interface IQuestion {
  assessment: string;
  title: string;
  statement: string;
  type: string;
  domain: string;
  skill: string;
  difficultyLevel: DifficultyLevel;
  durationInSeconds: number;
  points: number;
  mcqQuestionOptions: McqQuestionOptions;
  codingChallengeQuestionOptions: CodingChallengeQuestionOptions;
  testCode: string;
}

interface EditMcqQuestionProps {
  data: any
  id: string;
  formTitle?: string;
  mutate: any;
}

const EditQuestionForm = ({ id, data, formTitle, mutate: mutateQuestion }: EditMcqQuestionProps) => {
  const query: any = {};
  const { assesments, isLoading, mutate: mutateAssessments } = useAssesments();
  const { domains, mutate: mutateDomains, isLoading: isDomainsLoading } = useDomains(query);
  const { skills, mutate: mutateSkills, isLoading: isSkillsLoading } = useSkills(query);
  const { title, statement, skill, assessment, durationInSeconds, points, options, domain, difficultyLevel, tags, type, testCode } = data;

  const initialValues: IQuestion =
  {
    assessment,
    title,
    statement,
    skill,
    type: type,
    durationInSeconds,
    points,
    mcqQuestionOptions: {
      hasMultipleAnswers: false,
      isRandomOrderEnabled: false
    },
    domain,
    difficultyLevel,
    codingChallengeQuestionOptions: {
      programmingLanguage: 'javascript',
      template: 'vanilla-jd',
      challengeType: 'programming knowledge',
      solution: options.solution,
    },
    testCode,
  };

  type == "mcq" ? initialValues['mcqQuestionOptions'] = options : initialValues['codingChallengeQuestionOptions'] = options;

  useEffect(() => {
    mutateAssessments();
    mutateQuestion();
    mutateSkills();
    mutateDomains();
  }, [mutateAssessments, mutateQuestion, mutateSkills, mutateDomains]);

  if (isLoading || isDomainsLoading || isSkillsLoading) return null;

  const difficultyLevelOptions = [
    { value: 'easiest', label: 'easiest' },
    { value: 'easy', label: 'easy' },
    { value: 'medium', label: 'medium' },
    { value: 'harder', label: 'harder' },
    { value: 'hardest', label: 'hardest' }
  ];

  const assessmentOptions = assesments.map((assessment: any) => {
    return {
      value: assessment.id,
      label: assessment.title
    }
  });

  const skillOptions = skills.map((skill: any) => {
    return {
      value: skill.id,
      label: skill.title
    }
  });

  const domainOptions = domains.map((domain: any) => {
    return {
      value: domain.id,
      label: domain.title
    }
  });

  const templateOptions = [
    { value: "react", label: "react" },
    { value: "vanilla-js", label: "vanilla js" }
  ]

  return (
    <>
      <h2 className="text-xl mt-2 mb-5 font-medium">{formTitle}</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          const res = await updateQuestion(values, id)
          if (res.status === 200) {
            if (type === "mcq") {
              notifySuccess('MCQ question is updated successfully')
            } else {
              notifySuccess('Coding question is updated successfully')
            }
            mutateQuestion()
          }
        }}
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <Card classNames='px-4 py-5'>
                    <div className="mb-5">
                      <label htmlFor="title">Title</label>
                      <InputBox
                        type="text"
                        name="title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="assessment">Assessment</label>
                      <SelectField
                        options={assessmentOptions}
                        defaultValue={assessmentOptions.find((option: any) => values.assessment === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("assessment")(selectedOption.value)
                        }}
                        name="assessment"
                        placeholder="select assessment"
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="skill">Skill</label>
                      <SelectField
                        options={skillOptions}
                        defaultValue={skillOptions.find((option: any) => values.skill === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("skill")(selectedOption.value)
                        }}
                        name="skill"
                        placeholder='select skill'
                      />
                    </div>

                    {type === "coding_challenge" ? <>
                    <div className="mb-5">
                      <label htmlFor="diff-level">Template</label>
                      <SelectField
                        options={templateOptions}
                        defaultValue={templateOptions.find(option => values.codingChallengeQuestionOptions.template === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("codingChallengeQuestionOptions.template")(selectedOption.value)
                        }}
                        name="codingChallengeQuestionOptions.template"
                      />
                    </div>
                    </>: null}

                    {type === "mcq" ? (
                      <>
                        <div className="mb-5">
                          <SwitchToggle label="Multiple answer" enabled={values.mcqQuestionOptions.hasMultipleAnswers} onChange={() => {
                            setFieldValue("mcqQuestionOptions.hasMultipleAnswers", !values.mcqQuestionOptions.hasMultipleAnswers)
                          }} />
                        </div>

                        <div className="mb-5">
                          <SwitchToggle label="Random order" enabled={values.mcqQuestionOptions.isRandomOrderEnabled} onChange={() => {
                            setFieldValue("mcqQuestionOptions.isRandomOrderEnabled", !values.mcqQuestionOptions.isRandomOrderEnabled)
                          }} />
                        </div>
                      </>
                    ) : null}

                  </Card>
                </div>
                <div className="col-span-1">
                  <Card classNames='px-4 py-5'>
                    <div className="mb-5">
                    <label htmlFor="domain">Domain</label>
                      <SelectField
                        options={domainOptions}
                        defaultValue={domainOptions.find((option: any) => values.domain === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("domain")(selectedOption.value)
                        }}
                        name="domain"
                        placeholder='select domain'
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="diff-level">Difficulty level</label>
                      <SelectField
                        options={difficultyLevelOptions}
                        defaultValue={difficultyLevelOptions.find(option => values.difficultyLevel === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("difficultyLevel")(selectedOption.value)
                        }}
                        name="difficultyLevel"
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="duration-in-sec">Duration (second)</label>
                      <InputBox
                        type="number"
                        name="durationInSeconds"
                        id="duration-in-sec"
                        defaultValue={durationInSeconds}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="points">Points</label>
                      <InputBox
                        type="number"
                        name="points"
                        id="points"
                        defaultValue={points}
                        onChange={handleChange}
                      />
                    </div>
                  </Card>

                </div>
                <div className="col-span-2">
                  <div className="col-span-2 mb-14">
                    <span className="block mt-5 mb-1">Statement</span>
                    <Field name="statement">
                      {({ field }: any) => <TextEditor value={field.value} onChange={field.onChange(field.name)} />}
                    </Field>
                  </div>
                </div>
              </div>

              <div>
                {type === "coding_challenge" ? (
                  <>
                    <div className="mb-5">
                      <label htmlFor="testCode">Test code</label>
                      <Editor
                        height="20vh"
                        defaultLanguage="javascript"
                        value={values.testCode}
                        onChange={(value) => {
                          setFieldValue('testCode', value)
                        }}
                        className="border rounded md"
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="testCode">Solution</label>
                      <Editor
                        height="20vh"
                        defaultLanguage="javascript"
                        value={values.codingChallengeQuestionOptions.solution}
                        onChange={(value) => {
                          setFieldValue('codingChallengeQuestionOptions.solution', value)
                        }}
                        className="border rounded md"
                      />
                    </div>
                  </>
                ) : null}
              </div>

              <div className="mt-2">
                <Button type="submit"
                  className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                  disabled={isSubmitting}
                  height="10%">
                  <div className="flex">
                    <span>
                      Update
                    </span>
                    {isSubmitting ?
                      <span className="ml-2 mt-1">
                        <TailSpin
                          height="20"
                          width="20"
                          color="#000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </span>
                      : null}
                  </div>
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}

const EditQuestion = () => {
  const { id } = useParams();
  const { question, mutate, isLoading } = useQuestionDetails(id!)
  useEffect(() => {
    mutate()
  }, [mutate]);
  if (isLoading) return <Spinner />

  return (
    <>
      {question.type === "mcq" ? <EditQuestionForm id={id!} data={question} formTitle="MCQ question" mutate={mutate} /> : <EditQuestionForm id={id!} data={question} formTitle="Coding challenge question" mutate={mutate} />}
      <Notification />
    </>
  )
}

export default EditQuestion