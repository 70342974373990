import { XMarkIcon } from '@heroicons/react/24/outline';

interface AlertProps {
    variant: 'error' | 'info' | 'success' | 'warning';
    message: JSX.Element;
    showButton?: boolean
}

const Alert = ({ variant, message, showButton }: AlertProps ) => {
    const variants = {
        error: {
            baseClassName: 'rounded-md bg-red-50 p-4 mt-10',
            closeButtonClassName:
                'inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-2 focus:ring-offset-red-50 focus:ring-red-600',
            messageClassName: 'text-sm font-medium text-red-500',
        },
        info: {
            baseClassName: 'rounded-md bg-blue-50 p-4 mt-10',
            closeButtonClassName:
                'inline-flex bg-blue-50 rounded-md p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-2 focus:ring-offset-blue-50 focus:ring-blue-600',
            messageClassName: 'text-sm font-medium text-blue-500',
        },
        success: {
            baseClassName: 'rounded-md bg-green-50 p-4 mt-10',
            closeButtonClassName:
                'inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-2 focus:ring-offset-green-50 focus:ring-green-600',
            messageClassName: 'text-sm font-medium text-green-500',
        },
        warning: {
            baseClassName: 'rounded-md bg-orange-50 p-4 mt-10',
            closeButtonClassName:
                'inline-flex bg-orange-50 rounded-md p-1.5 text-orange-500 hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-orange-2 focus:ring-offset-orange-50 focus:ring-orange-600',
            messageClassName: 'text-sm font-medium text-orange-500',
        },
    }

    return (
        <div className={variants[variant].baseClassName}>
            <div className="flex">
                <div className="ml-3">
                    <p className={variants[variant].messageClassName}>
                        {message}
                    </p>
                </div>
                {showButton ? <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            className={variants[variant].closeButtonClassName}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>: null}
            </div>
        </div>
    )
}

export default Alert