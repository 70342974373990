import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditorLayout from '../components/editor/EditorLayout';
import { useQuestionDetails } from '../hooks/questions';
import Spinner from '../ui/Spinner';
import { QuestionContext } from './Questions';

export default function TestEditor() {
    const { questionId } = useParams()
    const { question, isLoading, mutate } = useQuestionDetails(questionId!);

    const formik = useFormik({
        initialValues: {
            testCasesCandidateAnswers: [],
            mcqCandidateAnswers: []
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
        }
    })

    useEffect(() => {
        mutate()
    }, [mutate])

    if (isLoading) {
        return <Spinner />
    }

    return (
        <>
            <QuestionContext.Provider value={{ question, mutate, isLoading }}>
                <form onSubmit={formik.handleSubmit}>
                    <EditorLayout handleChange={formik.handleChange} values={formik.values} />
                    <button type="submit" className="border py-2 px-5 rounded-md ml-5">submit</button>
                </form>
            </QuestionContext.Provider>

        </>
    )
}