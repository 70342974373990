import { SandpackCodeEditor, SandpackFileExplorer, useSandpack } from "@codesandbox/sandpack-react"
import { useContext, useEffect, useState } from "react";
import classnames from 'classnames'
import { QuestionContext } from "../../routes/Questions";
import { deleteDependencyFromQuestionAnswer, updateQuestionAnswer } from "../../services/questionAnswers";

interface EditorProps {
    showTabs?: boolean;
    showLineNumbers?: boolean;
    showInlineErrors?: boolean;
    wrapConent?: boolean;
    values?: any;
}

const Editor = ({
    showTabs,
    showLineNumbers,
    showInlineErrors,
    wrapConent,
    values
}: EditorProps) => {
    const { sandpack } = useSandpack();
    const { files, activeFile, setActiveFile } = sandpack;
    const { question: questionDetails, mutate } = useContext(QuestionContext)
    values.solution = files;

    const { questionAnswer, mutateQuestionAnswer } = useContext(QuestionContext)

    const [searchContext, setSearchContext] = useState<any>("");
    const [text, setText] = useState<string>("");
    const [error, setError] = useState<string>("");


    useEffect(() => {
        mutateQuestionAnswer()
    }, [mutateQuestionAnswer])


    async function updatingQuestionForTestCodeVisibility() {
        try {
            const response = await updateQuestionAnswer({ isTestCodeViewed: true }, questionAnswer.id)
            mutateQuestionAnswer()
        }
        catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (questionAnswer.isTestCodeViewed === false) {
            if (activeFile === "/index.test.js") {
                const revealTestFile = window.confirm('Warning!!! Want to open up the test file? 10 marks will be deducted')
                if (revealTestFile === false) {
                    setActiveFile(Object.keys(files)[0]); // set a default file
                }
                else {
                    updatingQuestionForTestCodeVisibility()
                }
            }
        } else {
            values.isTestCodeViewed = true;
        }
    }, [activeFile])

    const fetchNpmPackage = async (text: string) => {
        try {
            const data = await fetch(`https://api.npms.io/v2/search/suggestions?q=${text}`);
            const res = await data.json();
            if (res.length > 0) {
                await updateQuestionAnswer({
                    dependencies: [
                        {
                            "name": res[0].package.name,
                            "version": res[0].package.version,
                            "isDeletable": true,
                        }
                    ]
                }, questionAnswer.id)
                mutateQuestionAnswer()
            } else {
                setError("package not found")
            }
        }
        catch (error: any) {
            setError(error.response.data.message ? error.response.data.message : "something went wrong")
        }
    }

    const onChangeHandler = (event: any) => {
        event.preventDefault();
        setSearchContext(event.target.value);
    };

    const filterUniquePackage = (arr: any[]) => {
        let uniqueArr = arr.filter((obj, pos, arr) => {
            return arr
                .map(mapObj => mapObj.name)
                .indexOf(obj.name) == pos;
        });
        return uniqueArr;
    };

    const filteredDependencies = filterUniquePackage(questionAnswer.dependencies)

    const deleteDepedency = async (questionAnswerId: string, dependencyId: string) => {
        const response = await deleteDependencyFromQuestionAnswer(questionAnswerId, dependencyId);
        mutateQuestionAnswer()
    }

    return (
        <>
            <div className="container mx-auto">
                <div className="flex flex-row flex-wrap">
                    <aside className="w-full sm:w-1/3 md:w-1/4 border-r">
                        <div className="sticky top-0 p-0.5 w-full">
                            <SandpackFileExplorer autoHiddenFiles />
                            <div className="flex px-3">
                                <input type="text" placeholder="enter package name" className={classnames("w-32 rounded-md py-0.5 px-1", error? "border-2 border-red-600" : "border")}
                                    onChange={onChangeHandler}
                                    value={searchContext}
                                />
                                <span className="rounded-md border px-1.5 py-1 ml-1 text-center cursor-pointer"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setText(searchContext);
                                        fetchNpmPackage(searchContext)
                                        setSearchContext("");
                                        setError("")
                                    }}
                                >+</span>
                            </div>
                            {error ? <small className="ml-4 text-red-600">{error}</small> : null}

                            <ul className="flex flex-col h-[150px] overflow-y-auto px-3 mt-2">
                                {filteredDependencies.map((dependency: any, index: number) => {
                                    return <li className="py-1" key={index}>
                                        <span>{dependency?.name}</span>
                                        <span className="ml-3">{dependency?.version}</span>
                                        {dependency.isDeletable ?
                                            <strong className="ml-5 cursor-pointer hover:text-red-500" onClick={() => {
                                                deleteDepedency(questionAnswer.id, dependency._id)
                                            }}>x</strong> : null
                                        }
                                    </li>
                                })}
                            </ul>
                        </div>
                    </aside>
                    <main role="main" className="w-full sm:w-2/3 md:w-3/4 pt-1 px-2">
                        <SandpackCodeEditor
                            showTabs={showTabs}
                            showLineNumbers={showLineNumbers}
                            showInlineErrors={showInlineErrors}
                            wrapContent={wrapConent}
                            closableTabs
                        />
                    </main>
                </div>
            </div>
        </>
    )
}

export default Editor