import { SandpackConsole } from "@codesandbox/sandpack-react"

const ConsolePreviewer = () => {
  return (
    <>
        <SandpackConsole />
    </>
  )
}

export default ConsolePreviewer