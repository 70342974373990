import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDomains } from '../../../hooks/domains';
import { createDomain } from '../../../services/domains';
import Alert from '../../../ui/Alert';
import Button from '../../../ui/Button';
import InputBox from '../../../ui/InputBox'
import Spinner from '../../../ui/Spinner';
import DomainList from './DomainList';

const Domain = () => {
    const [page, setPage] = useState<number>(1)
    const [errors, setErrors] = useState<string[]>([])
    const [searchContext, setSearchContext] = useState<string[]>([])
    const query: any = {}
    query['limit'] = 5;
    query['page'] = page;
    query['searchTerm'] = searchContext;
    let timeOutId: any;

    const { domains, isLoading: isDomainLoading, mutate: mutateDomain, meta } = useDomains(query);

    useEffect(() => {
        mutateDomain()
    }, [mutateDomain])

    const initialDomainValue: any =
    {
        title: "",
    };

    const handleSearch = (event: any) => {
        if(timeOutId){clearTimeout(timeOutId)}
        timeOutId =  setTimeout(() => {setSearchContext(event.target.value)},500)
    }

    const formik = useFormik({
        initialValues: initialDomainValue,
        onSubmit: async (values, helper) => {
            try {
                helper.resetForm();
                mutateDomain([...domains, values])
                await createDomain(values)
                mutateDomain()
            }
            catch (error: any) {
                if (error.response.status === 400 || error.response.status === 409) {
                    setErrors(error.response.data.errorMessages)
                }
                console.error(error)
            }
        }
    });

    if (isDomainLoading) return <Spinner />;
    const { values, handleChange, handleSubmit } = formik;

    return (
        <>
            <div className="flex justify-left ml-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-1 sm:col-span-1">
                            <label htmlFor="domain-name" className="block text-sm font-medium text-gray-700">
                                Domain name
                            </label>
                            <InputBox
                                id="domain-name"
                                name={`title`}
                                placeholder="Enter domain name"
                                type="text"
                                value={values.title}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="col-span-1 sm:col-span-1">
                            <Button type="submit" className="px-3 py-1.5 bg-indigo-600 text-white rounded-md cursor-pointer mt-5" height="10%">
                                Create
                            </Button>
                        </div>
                    </div>
                </form>

                <div className='ml-auto'>
                    <div className="col-span-1 sm:col-span-1 mt-5">
                        <InputBox
                            autoFocus
                            name={`searchContext`}
                            placeholder="search"
                            type="search"
                            defaultValue={searchContext}
                            onChange={handleSearch}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className='flex justify-left ml-8'>
                {errors.length > 0 ? (
                    <>
                        <Alert variant='error' message={
                            <>
                                <h4 className="font-semibold">Errors</h4>
                                <ul className="list-disc ml-3">
                                    {errors.map((error: any) => {
                                        return <li>{error}</li>
                                    })}
                                </ul>
                            </>
                        } />
                    </>
                ) : null}
            </div>
            <div>
                <DomainList domains={domains} mutate={mutateDomain} pagination={{ meta, page, setPage }} />
            </div>
        </>
    )
}

export default Domain