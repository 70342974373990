import classNames from 'classnames';
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { useQuestionDetails } from '../../../../hooks/questions';
import { deleteTestCases, updateQuestion, updateTestCases } from '../../../../services/questions';
import Button from '../../../../ui/Button';
import Card from '../../../../ui/Card'
import InputBox from '../../../../ui/InputBox'
import Notification, { notifySuccess } from '../../../../ui/Notificaiton';
import Spinner from '../../../../ui/Spinner';
import { TestCasesEntity } from '../CreateCodingChallenges';

interface ITestCase extends TestCasesEntity {
  id: string
}
interface BoilerplateFormProps {
  testcase: ITestCase;
  mutate?: any;
  questionId: string;
  questionData: any;
}

const EditTestCases = () => {
  const { id } = useParams();
  const { question: data, mutate, isLoading } = useQuestionDetails(id!)

  useEffect(() => {
    mutate()
  }, [mutate]);

  if (isLoading) return <Spinner />

  return (
    <>
      <h2 className="font-medium text-xl my-3">{data?.title}</h2>
      <h4 className="font-medium mb-2">Test cases ({data?.testCases!.length})</h4>
      <div className="h-full overflow-y-auto">
        {data?.testCases!.length > 0 &&
          data?.testCases!.map((testcase: any, index: number) => {
            return (
              <TestCaseForm key={index} testcase={testcase} mutate={mutate} questionId={id!} questionData={data} />
            )
          })}
      </div>
      <Button
        type="button"
        className="border rounded-md px-3 mt-1 py-1 bg-indigo-600 text-white"
        onClick={async () => {
          await updateQuestion({
            testCases: [
              { title: 'test case <number>', points: 0 }
            ]
          }, data?.id)
          mutate()
        }}
      >
        + Add test cases
      </Button>
      <Notification />
    </>
  )
}

const TestCaseForm = ({ testcase, mutate, questionId, questionData }: BoilerplateFormProps) => {
  const initialBoilerplateValue: TestCasesEntity =
  {
    title: testcase.title,
    points: testcase.points,
  };

  const formik = useFormik({
    initialValues: initialBoilerplateValue,
    onSubmit: async (values) => {
      const res = await updateTestCases(values, questionId, testcase.id)
      if (res.status === 200) {
        notifySuccess('Testcase is updated successfully')
        mutate()
      }
    }
  })

  const { values, handleChange, handleSubmit, isSubmitting } = formik;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Card classNames="mb-2 px-2 py-3 w-2/5">
          <div className="grid grid-cols-3 gap-2">
            <div className="col">
              <InputBox
                name={`title`}
                placeholder="Enter title"
                type="text"
                value={values.title}
                onChange={handleChange}
              />
            </div>
            <div className="col text-center">
              <InputBox
                name={`points`}
                placeholder="Enter points"
                type="number"
                value={values.points}
                onChange={handleChange}
              />
            </div>
            <div className="flex col text-right">
              <Button type="submit"
                className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                disabled={isSubmitting}
                height="10%">
                <div className="flex">
                  <span>
                    Update
                  </span>
                  {isSubmitting ?
                    <span className="ml-2 mt-1">
                      <TailSpin
                        height="20"
                        width="20"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </span>
                    : null}
                </div>
              </Button>
              <Button
                type="button"
                className="border rounded-md bg-red-600 text-white px-4 py-1.5"
                onClick={async () => {
                  const updatedCases = questionData.testCases.filter((testCase: any) => testCase.id !== testcase.id)
                  await deleteTestCases(questionId, testcase.id);
                  mutate({ ...questionData, testCases: updatedCases })
                }}
              >
                remove
              </Button>
            </div>
          </div>
        </Card>
      </form>
    </>
  )
}

export default EditTestCases