import useSWR from "swr";
import config from './../config/default.json'
import useAxiosPrivate from "./useAxiosPrivate";
const userUrl = "/users";

export const useUserDetails = (id: string) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${userUrl}/${id}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        user: data?.data,
        mutate,
        error,
        isLoading: !data ? true : false,
        isError: error ? true : false
    }
}
