import { http } from "./http"

export const createTopics = async (body: any) => {
    return (await http.post(`/topics`, body))
}

export const deleteTopics = async (topicId: any) => {
    return (await http.delete(`/topics/${topicId}`))
}

export const updateTopics = async (body: any, topicId: any) => {
    return (await http.patch(`/topics/${topicId}`, body))
}

