import useSWR from "swr";
import useAxiosPrivate from "./useAxiosPrivate";
import config from './../config/default.json'
import qs from 'qs';
const questionUrl = "/questions";

export const useQuestionDetails = (id: string) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${questionUrl}/${id}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        question: data?.data,
        mutate,
        error,
        isLoading: !data? true : false,
        isError: error
    }
}

export const useQuestions = (query: any) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${questionUrl}?${qs.stringify(query)}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        questions: data?.data,
        meta: data?.meta,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}