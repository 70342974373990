import EditorLayout from '../editor/EditorLayout'

const CodingPattern = ({ handleChange, values }: any) => {
  return (
    <>
      <EditorLayout handleChange={handleChange} values={values}/>
    </>
  )
}

export default CodingPattern