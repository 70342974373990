export const secondToMinuteAndSecond = (second: number) => {
    const minute = Math.floor(second /60);
    const sec = second - minute * 60;
    return {
        minute,
        second: sec
    } 
}

export const secondToMilisecond = (second: number) => {
    return second * 1000
}