import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useUserPersonalAssesmentDetails } from '../hooks/userAssessments';
import Accordion from '../ui/Accordion';
import Badge from '../ui/Badge';
import Spinner from '../ui/Spinner';
import { toFixedWithNoRounding } from '../utils/custom-math';
import McqAnswers from '../components/user-assessment/McqAnswers';
import CodingChallengeAnswer from '../components/user-assessment/CodingChallengeAnswer';
import { formatDateTime } from '../utils/datetime-converter';
import NoDataFound from '../ui/empty-state/NoDataFound';
import ChartComponent from '../ui/PieChartComponent';

export default function UserAssessmentDetails() {
    const { id } = useParams();
    const { userPersonalAssessment, isLoading, mutate } = useUserPersonalAssesmentDetails(id!);

    useEffect(() => {
        mutate()
    }, [mutate]);

    if (isLoading) return <Spinner />;

    const statusVariant: any = {
        completed: 'success',
        started: 'warning'
    }

    const userAssessmentAnswer = userPersonalAssessment?.answers.map((answer: any, index: number) => {
        return {
            head: `${index + 1}. ${answer.question.title}`,
            body: answer?.question?.type === "mcq" ? <McqAnswers answer={answer} /> : <CodingChallengeAnswer answer={answer} />,
            status: answer.status
        }
    })

    const totalCorrectAnswer = userPersonalAssessment?.answers.filter((answer: any) => answer.status === "correct").length;

    const totalInCorrectAnswer = userPersonalAssessment?.answers.filter((answer: any) => answer.status === "incorrect").length;

    const totalPartiallyCorrectAnswer = userPersonalAssessment?.answers.filter((answer: any) => answer.status === "partially_correct").length;

    return (
        <div className="overflow-hidden bg-white sm:rounded-lg border">
            <div className="px-4 py-5 sm:px-6">
                <div>
                    <span className="text-lg font-medium leading-6 text-gray-900">
                        {userPersonalAssessment?.assessment?.title}
                    </span>
                    <span className="ml-1">
                        ({userPersonalAssessment?.assessment?.requiredExperience})
                    </span>
                </div>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    <Badge variant={statusVariant[userPersonalAssessment?.status]} text={userPersonalAssessment?.status === "started" ? "running" : userPersonalAssessment?.status} showButton={false} />
                </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Total points</dt>
                        <dd className="mt-1 text-sm text-gray-900">{userPersonalAssessment?.totalPoints} pts</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Scored</dt>
                        <dd className="mt-1 text-sm text-gray-900">{userPersonalAssessment?.totalScore} pts</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Time</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {userPersonalAssessment?.totalAssessmentTimeInSeconds ? `${toFixedWithNoRounding(userPersonalAssessment?.totalAssessmentTimeInSeconds)} sec` : "-"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Time taken</dt>
                        <dd className="mt-1 text-sm text-gray-900">{userPersonalAssessment?.totalTakenTimeInSeconds ? `${toFixedWithNoRounding(userPersonalAssessment?.totalTakenTimeInSeconds)} sec` : "-"}</dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Started at</dt>
                        <dd className="mt-1 text-sm text-gray-900">{formatDateTime(userPersonalAssessment?.startedAt)}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Ended at</dt>
                        <dd className="mt-1 text-sm text-gray-900">{userPersonalAssessment?.endedAt ? formatDateTime(userPersonalAssessment?.endedAt) : "-"}</dd>
                    </div>

                    <div className="sm:col-span-5 border-t">
                        <dt className="text-sm font-medium text-gray-500 mt-3">Questions history</dt>
                        <div className='text-sm text-gray-900 mt-2'>
                            <ChartComponent dataList={[
                                {
                                    label: "Incorrect",
                                    data: totalInCorrectAnswer,
                                    color: 'rgba(255, 99, 132, 0.5)'
                                },
                                {
                                    label: "Correct",
                                    data: totalCorrectAnswer,
                                    color: "rgba(75, 192, 192, 0.5)"
                                },
                                {
                                    label: "Partially correct",
                                    data: totalPartiallyCorrectAnswer,
                                    color: "rgba(255, 206, 86, 0.5)"
                                }
                            ]} />

                        </div>
                        {userAssessmentAnswer.length <= 0 ? (
                            <div className='mt-3'>
                                <NoDataFound variant='border' message='No answer found' />
                            </div>
                        ) : userPersonalAssessment.status === "started" ? <div className="mt-3">
                            <NoDataFound variant='border' message='Not visible yet' />
                        </div> : <Accordion data={userAssessmentAnswer} />}
                    </div>
                </dl>
            </div>
        </div>
    )
}
