import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid"
import { useFormik } from "formik"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import Pagination from "../../../components/Pagination"
import CodingPattern from "../../../components/questions/CodingPattern"
import McqPattern from "../../../components/questions/McqPattern"
import { useAssesments } from "../../../hooks/assessments"
import { useQuestions } from "../../../hooks/questions"
import { deleteQuestion, updateQuestion } from "../../../services/questions"
import Badge from "../../../ui/Badge"
import Button from "../../../ui/Button"
import InputBox from "../../../ui/InputBox"
import Modal from "../../../ui/Modal"
import { SelectField } from "../../../ui/SelectField"
import Spinner from "../../../ui/Spinner"
import Table from "../../../ui/Table"
import { QuestionContext } from "../../Questions"
import { secondToMinuteAndSecond } from './../../../utils/time-converter';
import { EnumQuestionStatus } from "./CreateMCQ"
import PreviewQuestion from "./PreviewQuestion"

interface FilterActionsProps {

  assessmentFilterConfig: any;
  levelFilterConfig: any;
  typeFilterConfig: any;
  searchFilterConfig: any;
  statusFilterConfig: any
  query?: Record<string, any>
}

const FilterActions = ({ assessmentFilterConfig, levelFilterConfig, typeFilterConfig, searchFilterConfig, statusFilterConfig, query }: FilterActionsProps) => {
  const { assesments, isLoading, mutate } = useAssesments()
  const [filterCount, setFilterCount] = useState(0)
  const searchFieldRef = useRef<any>(null)
  useEffect(() => { mutate() }, [mutate]);

  useEffect(() => {
    setFilterCount(Object.keys(query!).length)
  }, [query]);

  if (isLoading) return null;

  const { searchContext, setSearchContext } = searchFilterConfig;
  const { assessment, setAssessment } = assessmentFilterConfig;
  const { level, setLevel } = levelFilterConfig;
  const { type, setType } = typeFilterConfig;
  const { status, setStatus } = statusFilterConfig;

  let timeOutId: any;
  const handleSearch = (event: any) => {
    if (timeOutId) { clearTimeout(timeOutId) }
    timeOutId = setTimeout(() => { setSearchContext(event.target.value) }, 500)
  }

  const typeOptions = [
    { value: "mcq", label: "MCQ" },
    { value: "coding_challenge", label: "Coding Challenge" },
  ];

  const levelOption = [
    { value: "easier", label: "easier" },
    { value: "easy", label: "easy" },
    { value: "medium", label: "medium" },
    { value: "harder", label: "harder" },
    { value: "hardest", label: "hardest" },
  ];

  const statusOption = [
    { value: "pending", label: "pending" },
    { value: "approved", label: "approved" },
    { value: "rejected", label: "rejected" },
    { value: "test_code_added", label: "test code available" },
  ]

  const assessmentOptions = assesments.map((assessment: any) => {
    return {
      value: assessment.id,
      label: assessment.title
    }
  });

  return (
    <div className="flex justify-left">
      <SelectField
        options={assessmentOptions}
        defaultValue={assessmentOptions.find((option: any) => assessment === option.value)}
        onChange={(selectedOption: any) => {
          setAssessment(selectedOption.value)
        }}
        placeholder="select assessment"
        hideDefaultButton
      />
      <div className="ml-2">
        <SelectField
          defaultValue={typeOptions.find((option: any) => option.value === type)}
          options={typeOptions}
          onChange={(selectedOption: any) => {
            setType(selectedOption.value)
          }}
          placeholder="select type"
          hideDefaultButton
        />
      </div>

      <div className="ml-2">
        <SelectField
          defaultValue={levelOption.find((option: any) => option.value === level)}
          options={levelOption}
          onChange={(selectedOption: any) => {
            setLevel(selectedOption.value)
          }}
          placeholder="select level"
          hideDefaultButton
        />
      </div>

      <div className="ml-2">
        <SelectField
          defaultValue={statusOption.find((option: any) => option.value === status)}
          options={statusOption}
          onChange={(selectedOption: any) => {
            setStatus(selectedOption.value)
          }}
          placeholder="select status"
          hideDefaultButton
        />
      </div>

      <div className="ml-2">
        <InputBox
          autoFocus
          name={`searchContext`}
          placeholder="search"
          type="search"
          defaultValue={searchContext}
          onChange={handleSearch}
          fieldRef={searchFieldRef}
          required
        />
      </div>
      <div className="ml-2">
        <div className="col-span-1 sm:col-span-1 mt-2 text-indigo-600">
          <button className="flex"
            onClick={() => {
              setFilterCount(0)
              setAssessment("")
              setSearchContext("");
              setType("")
              setLevel("")
              setStatus("")
              searchFieldRef.current.value = ""
            }}
          >
            <strong className="relative inline-flex items-center px-2.5 text-xs font-medium">
              {filterCount > 0 ? <span className="absolute -top-2 right-0 h-5 w-5 rounded-full bg-red-600 flex justify-center items-center items text-white"><span>{filterCount}</span></span> : null}
              <AdjustmentsHorizontalIcon className="h-5 w-5" />
            </strong>
            <span className='font-normal text-sm mt-0.5'>Clear filters</span>
          </button>
        </div>
      </div>
    </div>
  )
}

const TableHeaders = () => {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
        >
          Title
        </th>
        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
          Type
        </th>
        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
          Stauts
        </th>
        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
          Duration (sec)
        </th>
        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
          level
        </th>
        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
          points
        </th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  )
}

const TableBody = ({ data, mutate }: any) => {
  const [openMcqPreview, setOpenMcqPreview] = useState<boolean>(false)
  const [openCodingPreview, setOpenCodingPreview] = useState<boolean>(false)
  const [questionData, setQuestionData] = useState<any>({})
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openEditStatusModal, setOpenEditStatusModal] = useState<boolean>(false)

  let difficultyLevelRender: any = "";
  let statusRender: any;
  return (
    <>
      <tbody className="divide-y divide-gray-200">
        <>
          {data?.map((question: any) => {
            if (question.difficultyLevel === "easiest") {
              difficultyLevelRender = (<Badge text="Easiest" variant="info" showButton={false} />)
            }
            if (question.difficultyLevel === "easy") {
              difficultyLevelRender = (<Badge text="Easy" variant="success" showButton={false} />)
            }
            if (question.difficultyLevel === "medium") {
              difficultyLevelRender = (<Badge text="Medium" variant="warning" showButton={false} />)
            }
            if (question.difficultyLevel === "harder") {
              difficultyLevelRender = (<Badge text="Harder" variant="error" showButton={false} />)
            }
            if (question.difficultyLevel === "hardest") {
              difficultyLevelRender = (<Badge text="Hardest" variant="error" showButton={false} />)
            }

            if (question.status === "approved") {
              statusRender = (<>
                <span className="inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400" aria-hidden="true"></span>
                <span className="ml-1">
                  {question?.status}
                </span>
              </>)
            }
            if (question.status === "pending") {
              statusRender = (<>
                <span className="inline-block h-2 w-2 flex-shrink-0 rounded-full bg-yellow-400" aria-hidden="true"></span>
                <span className="ml-1">
                  {question?.status}
                </span>
              </>)
            }
            if (question.status === "rejected") {
              statusRender = (<>
                <span className="inline-block h-2 w-2 flex-shrink-0 rounded-full bg-red-400" aria-hidden="true"></span>
                <span className="ml-1">
                  {question?.status}
                </span>
              </>)
            }

            if (question.status === "test_code_added") {
              statusRender = (<>
                <span className="inline-block h-2 w-2 flex-shrink-0 rounded-full bg-blue-400" aria-hidden="true"></span>
                <span className="ml-1">
                  test code available
                </span>
              </>)
            }

            const duration = secondToMinuteAndSecond(question?.durationInSeconds)

            return (
              (
                <tr key={question?.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                    {question?.title}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-black">{question?.type === "mcq" ? <Badge text="MCQ" variant="info" showButton={false} /> : <Badge text="Coding Challenge" variant="warning" showButton={false} />}</td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-black">
                    {statusRender}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-black">{duration.minute}min {duration.second}sec</td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-black">{difficultyLevelRender}</td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-black">{question?.points} pts</td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                    <button onClick={() => {
                      if (question?.type === "mcq") {
                        setQuestionData(question)
                        setOpenMcqPreview(true)
                      }
                      else {
                        setQuestionData(question)
                        setOpenCodingPreview(true)
                      }
                    }} className="border rounded-md px-3 bg-black text-white">
                      Preview<span className="sr-only">, {question?.title}</span>
                    </button>
                    <Link to={`/questions/edit/${question?.id}`} className="border rounded-md px-3 py-0.5 bg-indigo-600 text-white hover:bg-indigo-700">
                      Edit<span className="sr-only">, {question?.title}</span>
                    </Link>

                    <button className="border rounded-md px-3 bg-indigo-600 text-white hover:bg-indigo-700"
                      onClick={() => {
                        setOpenEditStatusModal(true)
                        setQuestionData(question)
                      }}
                    >
                      Edit status
                      <span className="sr-only">, {question?.title}</span>
                    </button>

                    {question?.type === "mcq" ? (
                      <Link to={`/questions/edit/${question?.id}/mcq-answers`} className="border rounded-md px-3 py-0.5 bg-indigo-600 text-white hover:bg-indigo-700">
                        Edit mcq answers
                      </Link>
                    ) : (
                      <>
                        <Link to={`/questions/edit/${question?.id}/boilerplates`} className="border rounded-md px-3 py-0.5 bg-indigo-600 text-white hover:bg-indigo-700">
                          Edit boilerplate<span className="sr-only"> view boilerplate</span>
                        </Link>

                        <Link to={`/questions/edit/${question?.id}/testcases`} className="border rounded-md px-3 py-0.5 bg-indigo-600 text-white hover:bg-indigo-700">
                          Edit test cases<span className="sr-only">, edit test cases</span>
                        </Link>
                      </>
                    )}
                    <Link to={`/questions/edit/${question?.id}/topics`} className="border rounded-md px-3 py-0.5 bg-indigo-600 text-white hover:bg-indigo-700">
                      Edit topics<span className="sr-only">, Edit topics </span>
                    </Link>

                    <button className="border rounded-md px-3 bg-red-600 text-white hover:bg-red-700" onClick={() => {
                      setOpenModal(true)
                      setQuestionData(question)
                    }}>
                      Delete<span className="sr-only">, Delete</span>
                    </button>
                  </td>
                </tr>
              )
            )
          })}
        </>
      </tbody>

      <Modal
        title={`Want to delete ${questionData?.title} question?`}
        onClose={() => {
          setOpenModal(false)
          setQuestionData({})
        }}
        state={openModal}
        action={
          <>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-">
              <button
                className="rounded-md border border-transparent bg-green-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm mr-2"
                onClick={async () => {
                  setOpenModal(false)
                  const newQuestionList = data.filter((question: any) => questionData.id !== question.id)
                  await deleteQuestion(questionData.id)
                  mutate({ ...newQuestionList })
                }}
              >
                yes
              </button>
              <button
                form="question-form"
                className="rounded-md border border-transparent bg-red-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => {
                  setOpenModal(false)
                  setQuestionData({})
                }}
              >
                No
              </button>
            </div>
          </>
        }
      >
      </Modal>

      <PreviewQuestion
        state={openMcqPreview}
        onClose={() => {
          setOpenMcqPreview(!openMcqPreview)
        }}
        actions={<>
          <div className="mt-2">
            <Button type="button" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer" height="10%" onClick={() =>
              setOpenMcqPreview(false)
            }>
              close
            </Button>
          </div>
        </>}
        previewTitle="Question Preview"
        preview={
          <>
            {questionData ? <McqPattern questionData={questionData} handleChange={""} values={""} /> : <p>Nothing added yet</p>}
          </>
        }
        innerStyle="border-dashed"
      />

      <Modal
        title={`Want to Edit ${questionData?.title} question status?`}
        onClose={() => {
          setOpenEditStatusModal(false)
          setQuestionData({})
        }}
        state={openEditStatusModal}
      >
        <StatusUpdateForm
          question={questionData}
          extendedAction={<>
            <Button type="button" onClick={() => {
              setOpenEditStatusModal(false)
              setQuestionData({})
            }} className="ml-2 px-3 py-2 bg-red-600 text-white rounded-md cursor-pointer mt-5" height="10%">
              close
            </Button></>
          }
          mutate={mutate}
          modalConfigForForm={{ setOpenEditStatusModal, setQuestionData }}
        />
      </Modal>

      <PreviewQuestion
        state={openCodingPreview}
        onClose={() => {
          setOpenCodingPreview(!openCodingPreview)
        }}
        actions={<>
          <div className="mt-2">
            <Button type="button" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer" height="10%" onClick={() =>
              setOpenCodingPreview(false)
            }>
              close
            </Button>
          </div>
        </>}
        previewTitle="Question Preview"
        preview={
          <div>
            <QuestionContext.Provider value={{ question: questionData, mutate: () => { }, isLoading: false, questionAnswer: { dependencies: [] }, isLoadingQuestionAnswer: false, mutateQuestionAnswer: () => { } }}>
              {questionData ? <CodingPattern questionData={questionData} handleChange={""} values={{ solutions: { "src/index.js": "" } }} /> : <p>Nothing added yet</p>}
            </QuestionContext.Provider>
          </div>
        }
      />
    </>
  )
}


const StatusUpdateForm = ({ question, extendedAction, mutate, modalConfigForForm }: any) => {
  const initialValue: any =
  {
    status: question.status,
  };

  const statusOptions = [
    { value: EnumQuestionStatus.Pending, label: EnumQuestionStatus.Pending },
    { value: EnumQuestionStatus.Approved, label: EnumQuestionStatus.Approved },
    { value: EnumQuestionStatus.Rejected, label: EnumQuestionStatus.Rejected },
    { value: EnumQuestionStatus.TestCodeAdded, label: EnumQuestionStatus.TestCodeAdded },
  ]

  const { setOpenEditStatusModal, setQuestionData } = modalConfigForForm;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values, helper) => {
      await updateQuestion(values, question?.id)
      setOpenEditStatusModal(false)
      setQuestionData({})
      mutate()
    }
  });
  const { values, handleChange, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-span-1 sm:col-span-1">
        <select
          className="border rounded-md px-4 py-2"
          value={values.status}
          onChange={handleChange}
          name="status"
        >
          {
            statusOptions.map((option: any) => <option value={option.value}>{option.label}</option>)
          }
        </select>
      </div>

      <div className="flex justify-center">
        <Button type="submit" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer mt-5" height="10%">
          Submit
        </Button>
        {extendedAction}
      </div>
    </form>
  )
}


const QuestionList = () => {
  const [page, setPage] = useState<number>(1)
  const [assessment, setAssessment] = useState<string>("");
  const [searchContext, setSearchContext] = useState<string>("")
  const [type, setType] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const query: any = {}
  const queryParams: any = {}
  query['limit'] = 10;
  query['page'] = page;

  if(searchContext) {
    query['searchTerm'] = searchContext;
    queryParams["searchTerm"] = searchContext;
  }
  if (assessment) {
    query["assessment"] = assessment;
    queryParams["assessment"] = assessment;
  }
  if (type) {
    query["type"] = type;
    queryParams["type"] = type;
  }
  if (level) {
    query["difficultyLevel"] = level;
    queryParams["difficultyLevel"] = level;
  }
  if (status) {
    query["status"] = status;
    queryParams["status"] = status;
  }

  const { questions, isLoading, mutate, meta } = useQuestions(query);

  useEffect(() => {
    mutate()
  }, [mutate])

  if (isLoading) return <Spinner />;

  return (
    <>
      {questions?.length > 0 ? (
        <Table title="Questions" tagLine="MCQ & Coding challenge questions" tableHeaders={<TableHeaders />} tableBody={<TableBody data={questions} mutate={mutate} />} filterActions={<FilterActions assessmentFilterConfig={{ assessment, setAssessment }} levelFilterConfig={{ level, setLevel }} typeFilterConfig={{ type, setType }} searchFilterConfig={{ searchContext, setSearchContext }} statusFilterConfig={{ status, setStatus }} query={queryParams}/>} />
      ) : (
        <>
          <Table title="Questions" tagLine="MCQ & Coding challenge questions" tableHeaders={<TableHeaders />} tableBody={<TableBody data={questions} mutate={mutate} />} filterActions={<FilterActions assessmentFilterConfig={{ assessment, setAssessment }} levelFilterConfig={{ level, setLevel }} typeFilterConfig={{ type, setType }} searchFilterConfig={{ searchContext, setSearchContext }} statusFilterConfig={{ status, setStatus }} query={queryParams} />} />
          <p className="flex flex-col items-center my-40 font-bold text-xl">
            No data available
          </p>
        </>
      )}
      <Pagination meta={meta} setPage={setPage} page={page} />
    </>
  )
}

export default QuestionList