import classnames from 'classnames';

interface CardProps {
    children: any;
    classNames?: string;
}

const Card = ({ children, classNames }: CardProps) => {
    return (
        <div className={classnames("bg-white sm:px-6 border rounded-md", classNames)}>
            {children}
        </div>
    )
}

export default Card