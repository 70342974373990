import classNames from 'classnames';
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { useQuestionDetails } from '../../../../hooks/questions';
import { deleteMcqAnswer, updateMcqAnswer, updateQuestion } from '../../../../services/questions';
import Button from '../../../../ui/Button';
import Card from '../../../../ui/Card'
import InputBox from '../../../../ui/InputBox'
import Notification, { notifySuccess } from '../../../../ui/Notificaiton';
import Spinner from '../../../../ui/Spinner';
import SwitchToggle from '../../../../ui/SwitchToggle';
import { McqAnswersEntity } from '../CreateMCQ';

interface IMcqAnswer extends McqAnswersEntity {
  id: string
}
interface McqAnswersFormProps {
  mcqAnswer: IMcqAnswer;
  mutate?: any;
  questionId: string;
  questionData: any;
}

const EditMcqAnswers = () => {
  const { id } = useParams();
  const { question: data, mutate, isLoading } = useQuestionDetails(id!)

  useEffect(() => {
    mutate()
  }, [mutate]);

  if (isLoading) return <Spinner />

  return (
    <>
      <h2 className="font-medium text-xl my-3">{data?.title}</h2>
      <h4 className="font-medium mb-2">MCQ answers ({data?.mcqAnswers!.length})</h4>
      <div className="h-full overflow-y-auto">
        {data?.mcqAnswers!.length > 0 &&
          data?.mcqAnswers!.map((mcqAnswer: any, index: number) => {
            return (
              <McqAnswersForm key={index} mcqAnswer={mcqAnswer} mutate={mutate} questionId={id!} questionData={data} />
            )
          })}
      </div>
      <Button
        type="button"
        className="border rounded-md px-3 mt-1 py-1 bg-indigo-600 text-white"
        onClick={async () => {
          const res = await updateQuestion({
            mcqAnswers: [
              { value: 'default', isCorrectAnswer: false }
            ]
          }, data.id)
          mutate()
        }}
      >
        + Add mcq answers
      </Button>
      <Notification />
    </>
  )
}

const McqAnswersForm = ({ mcqAnswer, mutate, questionId, questionData }: McqAnswersFormProps) => {
  const initialMcqValue: McqAnswersEntity =
  {
    value: mcqAnswer.value,
    isCorrectAnswer: mcqAnswer.isCorrectAnswer
  };

  const formik = useFormik({
    initialValues: initialMcqValue,
    onSubmit: async (values) => {
      const res = await updateMcqAnswer(values, questionId, mcqAnswer.id)
      if (res.status === 200) {
        notifySuccess('MCQ answer is updated successfully')
        mutate()
      }
    }
  })

  const { values, handleChange, setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Card classNames="mb-2 px-2 py-3 w-2/4" key={mcqAnswer.id}>
          <div className="grid grid-cols-3 gap-4" >
            <div className="col">
              <InputBox
                name={`value`}
                placeholder="Enter mcq value"
                type="text"
                value={values.value}
                onChange={handleChange}
              />
            </div>
            <div className="col text-center">
              <SwitchToggle label="answer" enabled={values.isCorrectAnswer} onChange={() => {
                setFieldValue(`isCorrectAnswer`, !values.isCorrectAnswer)
              }} className="mt-2 display-block" />

            </div>
            <div className="flex col text-right">
              <Button type="submit"
                className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                disabled={isSubmitting}
                height="10%">
                <div className="flex">
                  <span>
                    Update
                  </span>
                  {isSubmitting ?
                    <span className="ml-2 mt-1">
                      <TailSpin
                        height="20"
                        width="20"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </span>
                    : null}
                </div>
              </Button>
              <Button
                type="button"
                className="border rounded-md bg-red-600 text-white px-4 py-1.5"
                onClick={async () => {
                  const updatedMcqAnswers = questionData.mcqAnswers.filter((answer: any) => answer.id !== mcqAnswer.id)
                  await deleteMcqAnswer(questionId, mcqAnswer.id)
                  mutate({ ...questionData, mcqAnswers: updatedMcqAnswers })
                }}
              >
                remove
              </Button>
            </div>
          </div>
        </Card>
      </form>
    </>
  )
}

export default EditMcqAnswers