import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
// import { Chart } from 'react-chartjs-2';


import Button from './Button';
// import { ActionBar } from './page-title-element';
// const tailwindConfig = require('../../../tailwind.config');

ChartJS.register(ArcElement, Tooltip, Legend);

export interface PieChartComponentProps {
	dataList: any;
}

export default function PieChartComponent({
	dataList
}: PieChartComponentProps) {

	const labels: any = []
	const dataSet: any = [];
	const backgroundColors: any = [];
	dataList.filter((chartData: any) => {
		labels.push(chartData.label)
		dataSet.push(chartData.data)
		backgroundColors.push(chartData.color)
	})

	const data = {
		labels: labels,
		datasets: [
			{
				label: '# of Votes',
				data: dataSet,
				backgroundColor: backgroundColors,
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
				],
				borderWidth: 0,
				hoverOffset: 4
			},
		],
	};

	return (
		<>
			<Pie data={data} height={"200%"} width={"200%"} options={{ maintainAspectRatio: false }} />
		</>
	);
}
