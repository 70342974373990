import React from 'react'
import classnames from 'classnames'

interface InputBoxProps extends React.HTMLProps<HTMLInputElement> {
    name: string;
    type: string;
    classNames?: string;
    fieldRef?: any;
}

const InputBox = ({ name, type, classNames, fieldRef, ...props}: InputBoxProps) => {
    return (
        <input
            name={name}
            type={type}
            ref={fieldRef}
            className={classnames("block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm", classNames)}
            {...props}
        />
    )
}

export default InputBox