import { Formik } from "formik"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import useAuth from "../context/useAuth"
import { registerUser } from "../services/user"
import InputBox from "../ui/InputBox"
import logo from './../images/logo.png'

export default function Signup() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('')
    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt=""
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Register account</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                        <Formik
                            initialValues={{ email: "", password: "" }}
                            onSubmit={async values => {
                                try{
                                    const response = await registerUser(values)
                                    if(response.statusCode === 200) {
                                        navigate('/')
                                    }
                                }
                                catch(error: any) {
                                    setErrorMessage(error.response.data.message);
                                }
                            }}
                        >
                            {(props) => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleSubmit
                                } = props;

                                return (
                                    <form className="space-y-6" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                                <InputBox
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <InputBox
                                                    type="password"
                                                    name="password"
                                                    defaultValue={values.password}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center text-sm">
                                                <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    Already have an account?
                                                </Link>
                                            </div>

                                            <div className="text-sm">

                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                disabled={isSubmitting}
                                            >
                                                Register
                                            </button>
                                        </div>
                                        <p className="text-red-600 text-center">{errorMessage}</p>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
