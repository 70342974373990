import { useNavigate } from "react-router-dom";
import { http } from "../services/http";

const useRefreshToken = () => {
    const navigate = useNavigate();
    const refresh = async () => {
        try {
            const response = await http.post('/auth/refresh-token', {
                refreshToken: localStorage.getItem('refreshToken')
            });
            const { accessToken, refreshToken } = response.data.data;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            return accessToken;
        } catch (error: any) {
            if (error.response.status === 401) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                navigate('/');
            }
        }
    }
    return refresh;
};

export default useRefreshToken;
