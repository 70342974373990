import { useParams } from 'react-router-dom';
import UserAssessmentContent from '../components/user-assessment/UserAssessmentContent'

export const UserAssessments = () => {
    const { status } = useParams();
    let title: string;
    if(status === "completed") title = "Completed User Assessments";
    else if(status === "started") title = "Running User Assessments";
    else title = "All User Assessments";

    return (
        <>
            <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
            <div className="mt-6">
                <UserAssessmentContent status={status} />
            </div>
        </>
    )
}
