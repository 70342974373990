import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../context/useAuth';
import { useUserDetails } from '../../hooks/users';
import { ROLE } from '../../models/Role';

const PrivateRoute = ({ children, roles }: { children: JSX.Element, roles: Array<ROLE> }) => {
    let location = useLocation();
   
    const { user } = useAuth();
    const { user: userDetails, isLoading, mutate } = useUserDetails(user!)
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        mutate()
    }, [mutate]);

    if (!accessToken && !refreshToken) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    if (isLoading) return null;

    const userHasRequiredRole = roles.includes(userDetails?.role) ? true : false;

    if (!userDetails || userHasRequiredRole === false) {
        return <Navigate to="/not-allowed" state={{ from: location }} />;
    }

    return children;
};

export default PrivateRoute;