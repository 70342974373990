import classNames from 'classnames';
interface MessageBlockProps {
    status: string
}
export const MessageBlock = ({ status } : MessageBlockProps) => (
   <span className={classNames("px-2 text-white uppercase font-bold", status === "pass" ? "bg-green-600": "bg-red-600")}>{status}</span>
)

export default function TestData({ testResult }: any) {
    const { status, name, duration } = testResult;
    return (
        <>
            <div className="flex text-sm">
                <span className="mt-1.5">
                    {status === "fail" ? <MessageBlock status="fail" /> : <MessageBlock status="pass" />}
                </span>
                <span className="ml-2 mt-1 font-normal text-black">{name}</span>
                <span className="ml-1 mt-1 font-normal text-black">({duration}ms)</span>
            </div>
        </>
    )
}