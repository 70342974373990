import classNames from 'classnames';

import { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'

export default function ComboBox({
    label,
    name,
    items,
    placeholder,
    displayValue,
    onChange,
}: any) {
    const [query, setQuery] = useState('')
    const [selectedItem, setSelectedItem] = useState(null)

    const filteredPeople =
        query === ''
            ? items
            : items.filter((item: any) => {
                return item.key.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
            <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
            <div className="relative mt-1">
                <Combobox.Input
                    placeholder={placeholder}
                    name={name}
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event: any) => {
                        setQuery(event.target.value)
                        onChange(name)(event)
                    }}
                    displayValue={displayValue}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredPeople.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((item: any, index: number) => {
                            return (
                                <Combobox.Option
                                    key={index}
                                    value={item.value}
                                    className={({ active }) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                            <>
                                                <span className={classNames('block truncate', selected && 'font-semibold')}>{item.key}</span>
        
                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-indigo-600'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                </Combobox.Option>
                            )
                        })}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    )
}
