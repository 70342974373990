import { SandpackPreview } from "@codesandbox/sandpack-react"

const Previewer = () => {
  return (
    <>
        <SandpackPreview className="h-[300px]" showNavigator/>
    </>
  )
}

export default Previewer