import useSWR from "swr";
import useAxiosPrivate from "./useAxiosPrivate";
import config from './../config/default.json'
import qs from 'qs';
import { validateQuery } from "../utils/validate-query";
const domainUrl = "/domains";

export const useDomains = (query: any) => {
    const filteredQuery = validateQuery(query)
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${domainUrl}?${qs.stringify(filteredQuery)}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        domains: data?.data,
        meta: data?.meta,
        mutate,
        error,
        isLoading: !data ? true : false,
        isError: error
    }
}