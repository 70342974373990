import { http } from "./http"

export const createSkill = async (body: any) => {
    return (await http.post(`/skills`, body))
}

export const deleteSkill = async (skillId: string) => {
    return (await http.delete(`/skills/${skillId}`))
}

export const updateSkill = async (body: any, skillId: string) => {
    return (await http.patch(`/skills/${skillId}`, body))
}

