import { useFormik } from 'formik'
import { useState } from 'react'
import Pagination from '../../../components/Pagination'
import { deleteTopics, updateTopics } from '../../../services/topics'
import Button from '../../../ui/Button'
import InputBox from '../../../ui/InputBox'
import Modal from '../../../ui/Modal'
import Table from '../../../ui/Table'

const TableHeaders = () => {
    return (
        <thead>
            <tr>
                <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                    Name
                </th>
                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Assessment
                </th>

                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                </th>
            </tr>
        </thead>
    )
}

const TopicForm = ({ topic, assessments, extendedAction, mutate, modalConfigForForm }: any) => {
    const initialTopicValue: any =
    {
        title: topic.title,
        assessment: topic.assessment
    };

    const { setOpenEditModal, setSelectedTopic } = modalConfigForForm;

    const formik = useFormik({
        initialValues: initialTopicValue,
        onSubmit: async (values, helper) => {
            await updateTopics(values, topic?.id)
            setOpenEditModal(false)
            setSelectedTopic({})
            mutate()
        }
    });
    const { values, handleChange, handleSubmit } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <div className="col-span-1 sm:col-span-1">
                <InputBox
                    id="topic-name"
                    name={`title`}
                    placeholder="Enter topic name"
                    type="text"
                    value={values.title}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="mt-2">

                <select
                    name="assessment"
                    id="assessment"
                    value={values.assessment}
                    onChange={handleChange}
                    className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                    <option value="">select an assessment</option>
                    {assessments.map((assesment: any, index: number) => {
                        return <option key={index} value={assesment.id}>{assesment.title}</option>
                    })}
                </select>
            </div>



            <div className="flex justify-center">
                <Button type="submit" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer mt-5" height="10%">
                    Submit
                </Button>
                {extendedAction}
            </div>
        </form>
    )
}


const TableBody = ({ data, mutate, assessments }: any) => {
    const [openEditModal, setOpenEditModal] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [selectedTopic, setSelectedTopic] = useState<any>({})

    function getAssessmentName(assessments: any[], id: string) {
        const result = assessments.find((assessment: any) => assessment.id === id)
        return result?.title
    }

    return (
        <>
            <tbody className="divide-y divide-gray-200">
                <>
                    {data?.map((topic: any) => {
                        return (
                            (
                                <tr key={topic?.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                        {topic?.title}
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-black">
                                        {getAssessmentName(assessments, topic?.assessment)}
                                    </td>

                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                        <button className="border rounded-md px-3 bg-indigo-600 text-white hover:bg-indigo-700" onClick={() => {
                                            setOpenEditModal(true)
                                            setSelectedTopic(topic)
                                        }}>
                                            edit<span className="sr-only">, edit</span>
                                        </button>
                                        <button className="border rounded-md px-3 bg-red-600 text-white hover:bg-red-700" onClick={() => {
                                            setOpenDeleteModal(true)
                                            setSelectedTopic(topic)
                                        }}>
                                            Delete<span className="sr-only">, Delete</span>
                                        </button>
                                    </td>
                                </tr>
                            )
                        )
                    })}
                </>
            </tbody>

            <Modal
                title={`Want to Edit ${selectedTopic?.title} topic?`}
                onClose={() => {
                    setOpenEditModal(false)
                    setSelectedTopic({})
                }}
                state={openEditModal}
            >
                <TopicForm 
                topic={selectedTopic} 
                assessments={assessments} 
                extendedAction={<>
                    <Button type="button" onClick={() => {
                        setOpenEditModal(false)
                        setSelectedTopic({})
                    }} className="ml-2 px-3 py-2 bg-red-600 text-white rounded-md cursor-pointer mt-5" height="10%">
                        close
                    </Button></>
                } 
                mutate={mutate}
                modalConfigForForm={{ setOpenEditModal, setSelectedTopic}}
                />
            </Modal>

            <Modal
                title={`Want to delete ${selectedTopic?.title} topic?`}
                onClose={() => {
                    setOpenDeleteModal(false)
                    setSelectedTopic({})
                }}
                state={openDeleteModal}
                action={
                    <>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-">
                            <button
                                className="rounded-md border border-transparent bg-green-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm mr-2"
                                onClick={async () => {
                                    const newTopicList = data.filter((topic: any) => selectedTopic.id !== topic.id)
                                    await deleteTopics(selectedTopic.id)
                                    mutate([ ...newTopicList ])
                                    setOpenDeleteModal(false)
                                }}
                            >
                                yes
                            </button>
                            <button
                                form="question-form"
                                className="rounded-md border border-transparent bg-red-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                onClick={() => {
                                    setOpenDeleteModal(false)
                                    setSelectedTopic({})
                                }}
                            >
                                No
                            </button>
                        </div>
                    </>
                }
            >
            </Modal>
        </>
    )
}

const TopicList = ({ assessments, topics, mutate, pagination }: any) => {
    const { meta, setPage, page } = pagination;
    return (
        <div>
            {topics?.length > 0 ? (
                <Table tableHeaders={<TableHeaders />} tableBody={<TableBody data={topics} mutate={mutate} assessments={assessments} />} />
            ) : (
                <>
                    <Table tableHeaders={<TableHeaders />} tableBody={<TableBody data={topics} mutate={mutate} />} />
                    <p className="flex flex-col items-center my-40 font-bold text-xl">
                        No data available
                    </p>
                </>
            )}
            <Pagination meta={meta} setPage={setPage} page={page} />
        </div>
    )
}

export default TopicList