import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames';

interface AccordionBody {
  head: any;
  body: any;
  status?: "correct" | "incorrect" | "partially_correct" | "none"
}

interface AccordionProps {
  data: AccordionBody []
}

export default function Accordion({ data }: AccordionProps) {  

  const statusColor: any = {
    correct: "bg-green-100",
    incorrect: "bg-red-100",
    partially_correct: "bg-yellow-100",
    none: "bg-white",
  }

  const statusIconColor: any = {
    correct: "text-green-700",
    incorrect: "text-red-700",
    partially_correct: "text-yellow-700",
    none: "text-white",
  }
  
  return (
    <div className="w-full pt-4">
      <div className="mx-auto w-full rounded bg-white">
        {data.map((el: any, index: number) => (
          <Disclosure key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button className={classNames("flex w-full justify-between rounded-lg border px-4 py-2 text-left text-sm font-medium text-black focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 mt-1", statusColor[el.status])}>
                <span>{el.head}</span>
                <ChevronUpIcon
                  className={classNames(open ? 'rotate-180 transform' : ''
                  ,`h-5 w-5`, el.status ? statusIconColor[el.status] : "text-indigo-500")}
                />
              </Disclosure.Button>
              <Transition
                enter="transition duration-500 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-500 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 transition-all">
                  {el.body}
                </Disclosure.Panel>

              </Transition>

            </>
          )}
        </Disclosure>
        ))}
      </div>
    </div>
  )
}
