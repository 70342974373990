import { BrowserRouter } from 'react-router-dom'
import Layouts from './components/Layouts';
import { AuthProvider } from './context/useAuth';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Layouts />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
