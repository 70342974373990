import classNames from 'classnames';
import Parser from 'html-react-parser';
import Badge from '../../ui/Badge';
import { toFixedWithNoRounding } from '../../utils/custom-math';
import { formatDateTime } from '../../utils/datetime-converter';
import { MessageBlock } from '../editor/Tests/TestData';

interface TestCaseAnswer {
    title: string;
    status: string;
    points: number;
    id: string;
}

interface TestCaseRendererProps {
    testCases: TestCaseAnswer[];
}

const TestCaseRenderer = ({ testCases }: TestCaseRendererProps) => {
    return (
        <>
            {testCases.map((testCase: TestCaseAnswer, index: number) => {
                return (
                    <dd key={index} className={classNames("mt-1 text-sm text-gray-900"
                    )}>
                        <div className="flex text-sm">
                            <span className="mt-1.5">
                                {testCase.status === "failed" ? <MessageBlock status="fail" /> : <MessageBlock status="pass" />}
                            </span>
                            <span className="ml-2 mt-1.5 font-normal text-black">{testCase.title}</span>
                            <b className="mt-1.5 ml-1">({testCase.points} pts)</b>
                        </div>
                    </dd>
                )
            })}
        </>
    )
}

const CodingChallengeAnswer = ({ answer }: any) => {
    const { status, points, comparativeScore, comparativeScoreInPercentage, remainingTimeInSeconds, startedAt, endedAt, totalTakenTimeInSeconds, testCases, question } = answer;
    const statusColor: any = {
        correct: "success",
        incorrect: "error",
        partially_correct: "warning",
        none: "info"
    }

    const levelColor: any = {
        easier: "info",
        easy: "success",
        medium: "warning",
        harder: "error",
        hardest: "error"
    }

    return (
        <>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {status ? <Badge variant={statusColor[status]} text={status === "partially_correct" ? "partially correct": status} showButton={false}/> : <Badge variant="info" text="none" showButton={false}/> }
                        
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Score</dt>
                    <dd className="mt-1 text-sm text-gray-900">{points ? points : "0"} pts</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Comparative score</dt>
                    <dd className="mt-1 text-sm text-gray-900">{toFixedWithNoRounding(comparativeScore)} pts</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Comparative score (%)</dt>
                    <dd className="mt-1 text-sm text-gray-900">{toFixedWithNoRounding(comparativeScoreInPercentage)}%</dd>
                </div>

                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Level</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        <Badge text={question?.difficultyLevel} variant={levelColor[question?.difficultyLevel]} showButton={false} />
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Time duration</dt>
                    <dd className="mt-1 text-sm text-gray-900">{question?.durationInSeconds} sec</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Started at</dt>
                    <dd className="mt-1 text-sm text-gray-900">{formatDateTime(startedAt)}</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Ended at</dt>
                    <dd className="mt-1 text-sm text-gray-900">{endedAt ? formatDateTime(endedAt) : "-"}</dd>
                </div>

                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Total taken time (sec)</dt>
                    <dd className="mt-1 text-sm text-gray-900">{toFixedWithNoRounding(totalTakenTimeInSeconds)} sec</dd>
                </div>

                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Remaining time (sec)</dt>
                    <dd className="mt-1 text-sm text-gray-900">{toFixedWithNoRounding(remainingTimeInSeconds)} sec</dd>
                </div>

                <div className="sm:col-span-4">
                    <dt className="text-sm font-medium text-gray-500">Statement</dt>
                    <dd className="mt-1 text-sm text-gray-900">{Parser(question?.statement)}</dd>
                </div>
                <div className="sm:col-span-4">
                    <dt className="text-sm font-medium text-gray-500">Coding test cases result</dt>
                    <TestCaseRenderer testCases={testCases}/>
                </div>
            </dl>

        </>
    )
}

export default CodingChallengeAnswer