import { Field, FieldArray, Formik } from 'formik'
import { PersistFormikValues } from 'formik-persist-values';
import { useEffect, useState } from 'react'
import Button from '../../../ui/Button';
import InputBox from '../../../ui/InputBox';
import SwitchToggle from '../../../ui/SwitchToggle';
import Card from '../../../ui/Card';
import { createQuestion } from '../../../services/questions';
import { useAssesments } from '../../../hooks/assessments';
import McqPattern from '../../../components/questions/McqPattern';
import Notification, { notifySuccess } from '../../../ui/Notificaiton';
import PreviewQuestion from './PreviewQuestion';
import Alert from '../../../ui/Alert';
import { TailSpin } from 'react-loader-spinner';
import classNames from 'classnames';
import TextEditor from '../../../ui/text-editor/TextEditor';
import { useDomains } from '../../../hooks/domains';
import { useSkills } from '../../../hooks/skills';
import { SelectField } from '../../../ui/SelectField';
import { TopicsField } from './TopicsField';

export enum EnumQuestionStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  TestCodeAdded = 'test_code_added',
}

export interface IMcqQuestion {
  assessment: string;
  title: string;
  statement: string;
  type: string;
  status: EnumQuestionStatus;
  domain: string;
  skill: string;
  difficultyLevel: DifficultyLevel;
  durationInSeconds: number;
  points: number;
  mcqQuestionOptions: McqQuestionOptions;
  mcqAnswers?: (McqAnswersEntity)[] | null;
  topics?: (string)[] | null;
}
export interface McqQuestionOptions {
  isRandomOrderEnabled: boolean;
  hasMultipleAnswers: boolean;
}
export interface McqAnswersEntity {
  value: string;
  isCorrectAnswer: boolean;
}

export enum DifficultyLevel {
  Easiest = "easiest",
  Easy = "easy",
  medium = "medium",
  Harder = "Harder",
  Hardest = "Hardest",
}

const CreateMCQ = () => {
  const mcqData = JSON.parse(localStorage.getItem("mcq-question-payload")!)
  if (mcqData) mcqData["options"] = mcqData.mcqQuestionOptions

  const [open, setOpen] = useState<boolean>(false);
  const { assesments, isLoading, mutate } = useAssesments();
  const query: any = {};

  const { domains, mutate: mutateDomains, isLoading: isDomainsLoading } = useDomains(query);

  const { skills, mutate: mutateSkills, isLoading: isSkillsLoading } = useSkills(query);

  const [errors, setErrors] = useState<string[]>([])
  const [globalError, setGlobalError] = useState<string>('')

  const initialMcqValue: IMcqQuestion =
  {
    assessment: '',
    title: '',
    statement: '',
    type: 'mcq',
    domain: '',
    status: EnumQuestionStatus.Pending,
    skill: '',
    difficultyLevel: DifficultyLevel.Easy,
    durationInSeconds: 0,
    points: 0,
    mcqQuestionOptions: {
      isRandomOrderEnabled: false,
      hasMultipleAnswers: false,
    },
    mcqAnswers: [
    ],
    topics: []
  };

  useEffect(() => {
    mutate();
    mutateSkills();
    mutateDomains();
  }, [mutate, mutateDomains, mutateSkills]);

  if (isLoading || isDomainsLoading || isSkillsLoading) return null;

  const difficultyLevelOptions = [
    { value: 'easiest', label: 'easiest' },
    { value: 'easy', label: 'easy' },
    { value: 'medium', label: 'medium' },
    { value: 'harder', label: 'harder' },
    { value: 'hardest', label: 'hardest' }
  ];

  const assessmentOptions = assesments.map((assessment: any) => {
    return {
      value: assessment.id,
      label: assessment.title
    }
  });

  const skillOptions = skills.map((skill: any) => {
    return {
      value: skill.id,
      label: skill.title
    }
  });

  const domainOptions = domains.map((domain: any) => {
    return {
      value: domain.id,
      label: domain.title
    }
  });

  return (
    <div>
      <h2 className="text-xl mt-2 mb-5 font-medium">MCQ question</h2>
      <Formik
        initialValues={{ ...initialMcqValue }}
        onSubmit={async (values, formikHelper) => {
          values.durationInSeconds = Number(values.durationInSeconds)
          values.points = Number(values.points)
          try {
            const res = await createQuestion(values)
            if (res.status === 200) {
              formikHelper.resetForm();
              setErrors([]);
              setGlobalError("");
              notifySuccess('MCQ question created successfully')
              localStorage.removeItem("mcq-question-payload")
            }

          } catch (error: any) {
            if (error.response.status === 400 && error.response.data.errorMessages.length > 0) {
              setErrors(error.response.data.errorMessages)
            } else {
              setGlobalError("something went wrong")
            }
            console.error(error)
          }
        }}
      >

        {(props) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            resetForm
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <Card classNames='px-4 py-5'>
                    <div className="mb-5">
                      <label htmlFor="title">Title</label>
                      <InputBox
                        type="text"
                        name="title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="assessment">Assessment</label>
                      <SelectField
                        options={assessmentOptions}
                        defaultValue={assessmentOptions.find((option: any) => values.assessment === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("assessment")(selectedOption.value)
                        }}
                        name="assessment"
                        placeholder="select assessment"
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="skill">Skill</label>
                      <SelectField
                        options={skillOptions}
                        defaultValue={skillOptions.find((option: any) => values.skill === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("skill")(selectedOption.value)
                        }}
                        name="skill"
                        placeholder='select skill'
                      />
                    </div>

                    <div className="mb-5">
                      <SwitchToggle label="Multiple answer" enabled={values.mcqQuestionOptions.hasMultipleAnswers} onChange={() => {
                        setFieldValue("mcqQuestionOptions.hasMultipleAnswers", !values.mcqQuestionOptions.hasMultipleAnswers)
                      }} />
                    </div>

                    <div className="mb-5">
                      <SwitchToggle label="Random order" enabled={values.mcqQuestionOptions.isRandomOrderEnabled} onChange={() => {
                        setFieldValue("mcqQuestionOptions.isRandomOrderEnabled", !values.mcqQuestionOptions.isRandomOrderEnabled)
                      }} />
                    </div>
                  </Card>
                </div>

                <div className="col-span-1">
                  <Card classNames='px-4 py-5'>
                    <div className="mb-5">
                      <label htmlFor="domain">Domain</label>
                      <SelectField
                        options={domainOptions}
                        defaultValue={domainOptions.find((option: any) => values.domain === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("domain")(selectedOption.value)
                        }}
                        name="domain"
                        placeholder='select domain'
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="diff-level">Difficulty level</label>
                      <SelectField
                        options={difficultyLevelOptions}
                        defaultValue={difficultyLevelOptions.find(option => values.difficultyLevel === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("difficultyLevel")(selectedOption.value)
                        }}
                        name="difficultyLevel"
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="duration-in-sec">Duration (second)</label>
                      <InputBox
                        type="number"
                        name="durationInSeconds"
                        id="duration-in-sec"
                        value={values.durationInSeconds}
                        onChange={handleChange}
                      />
                    </div>


                    <div className="mb-5">
                      <label htmlFor="points">Points</label>
                      <InputBox
                        type="number"
                        name="points"
                        id="points"
                        value={values.points}
                        onChange={handleChange}
                      />
                    </div>
                  </Card>
                </div>

                <div className="col-span-2">
                  <span className="block mb-1">Statement</span>
                  <Field name="statement">
                    {({ field }: any) => <TextEditor value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
                </div>

                <div className="col-span-2 mt-10">
                  <div className="mb-5">
                    <Card classNames='px-4 py-5'>
                      <h4 className="font-medium mb-2">MCQ answers ({values.mcqAnswers!.length})</h4>
                      <FieldArray name="mcqAnswers">
                        {({ insert, remove, push }) => (
                          <>
                            <div className="h-[220px] overflow-y-auto">
                              {values.mcqAnswers!.length > 0 &&
                                values.mcqAnswers!.map((mcqAnswer, index) => (
                                  <Card classNames="mb-2 px-2 py-3" key={index}>
                                    <div className="grid grid-cols-3 gap-4" key={index}>
                                      <div className="col">
                                        <InputBox
                                          name={`mcqAnswers.${index}.value`}
                                          placeholder="Enter mcq value"
                                          type="text"
                                          value={mcqAnswer.value}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div className="col text-center">
                                        <SwitchToggle label="answer" enabled={mcqAnswer.isCorrectAnswer} onChange={() => {
                                          setFieldValue(`mcqAnswers.${index}.isCorrectAnswer`, !mcqAnswer.isCorrectAnswer)
                                        }} className="mt-2 display-block" />

                                      </div>
                                      <div className="col text-right">
                                        <Button
                                          type="button"
                                          className="border rounded-md bg-red-600 text-white px-4 py-1.5"
                                          onClick={() => remove(index)}
                                        >
                                          remove
                                        </Button>
                                      </div>
                                    </div>
                                  </Card>
                                ))}

                            </div>
                            <Button
                              type="button"
                              className="border rounded-md px-3 mt-1 py-1 bg-indigo-600 text-white"
                              onClick={() => push({ value: '', isCorrectAnswer: false })}
                            >
                              + Add mcq answers
                            </Button>
                          </>
                        )}
                      </FieldArray>

                      {/* create and view tags  */}
                      <h4 className="font-medium my-4">Topics ({values?.topics!.length})</h4>
                      <TopicsField fields={props} />
                    </Card>
                  </div>
                </div>

              </div>

              {errors.length > 0 ? (
                <>
                  <Alert variant='error' message={
                    <>
                      <h4 className="font-semibold">Errors</h4>
                      <ul className="list-disc ml-3">
                        {errors.map((error: any) => {
                          return <li>{error}</li>
                        })}
                      </ul>
                    </>
                  } />
                </>
              ) : null}

              {globalError ? <>
                <Alert variant='error' message={
                  <>
                    {globalError}
                  </>
                } />
              </> : null}

              <div className="">
                <Button type="submit"
                  className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                  disabled={isSubmitting} height="10%">
                  <div className="flex">
                    <span>
                      Submit
                    </span>
                    {isSubmitting ?
                      <span className="ml-2 mt-1">
                        <TailSpin
                          height="20"
                          width="20"
                          color="#000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </span>
                      : null}
                  </div>
                </Button>

                <Button type="button" onClick={() => {
                  resetForm()
                  setErrors([])
                  setGlobalError("")
                  localStorage.removeItem("coding-challege-question-payload");
                }} className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer ml-2">
                  Reset Form
                </Button>

                <Button type="button" onClick={() => setOpen(true)} className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer ml-2 mt-16">
                  Preview
                </Button>
                <PersistFormikValues name="mcq-question-payload" />
              </div>
            </form>
          )
        }}
      </Formik>

      <PreviewQuestion
        state={open}
        onClose={() => {
          setOpen(!open)
        }}
        actions={<>
          <div className="mt-2">
            <Button type="button" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer" height="10%" onClick={() =>
              setOpen(false)
            }>
              close
            </Button>
          </div>
        </>}
        previewTitle="Question Preview"
        preview={<>
          {mcqData ? <McqPattern questionData={mcqData} handleChange={""} values={""} /> : <p>Nothing added yet</p>}
        </>}
      />
      <Notification />
    </div>
  )
}

export default CreateMCQ