import { Formik } from "formik"
import { useState } from "react"
import { Link } from "react-router-dom"
import { signInAsUser } from "../services/user"
import InputBox from "../ui/InputBox"
import logo from './../images/logo.png'

export default function Login() {
    const [errorMessage, setErrorMessage] = useState('')
    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt=""
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            onSubmit={async values => {
                                await new Promise(resolve => setTimeout(resolve, 500));
                                try {
                                    const response = await signInAsUser(values)
                                    if (response.statusCode === 200) {
                                        const { accessToken, refreshToken } = response.data
                                        localStorage.setItem('accessToken', accessToken)
                                        localStorage.setItem('refreshToken', refreshToken)
                                        window.location.href = '/dashboard'
                                    }
                                }
                                catch (error: any) {
                                    setErrorMessage(error.response.data.message);
                                }
                            }}
                        >
                            {(props) => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleSubmit
                                } = props;

                                return (
                                    <form className="space-y-6" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                                <InputBox
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <InputBox
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center text-sm">
                                                <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    Create an account
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                disabled={isSubmitting}
                                            >
                                                Sign in
                                            </button>
                                        </div>
                                        <p className="text-red-600 text-center">{errorMessage}</p>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
