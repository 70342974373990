import classNames from 'classnames';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './SubItem.css';
interface ISubMenItem {
    label: string;
    path: string;
    element: React.ReactElement
}

interface SubMenItemProps {
    label: string;
    path: string;
    subMenuItems: ISubMenItem[]
}
const SubItem = ({ label, path, subMenuItems }: SubMenItemProps) => {
    const location = useLocation()
    return (
        <>
            <div className="dropdown inline-block relative z-10">
                <button
                className={classNames(path === location.pathname
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    "rounded-md px-3 py-2 text-sm font-medium inline-flex items-center")}
                >
                    <span className="mr-1">{label} </span>
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                </button>
                <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 w-11/12">
                    {subMenuItems.map((item: ISubMenItem, index: number) => {
                        return (
                            <li key={index}>
                                <Link className="bg-gray-700 text-white hover:bg-gray-900 py-2 px-4 block whitespace-no-wrap w-52" to={item.path}>
                                    {item.label}
                                </Link>

                                
                            </li>
                        )
                    })}
                </ul>
            </div>

        </>
    )
}

export default SubItem