import useSWR from "swr";
import useAxiosPrivate from "./useAxiosPrivate";
import config from './../config/default.json'
import { validateQuery } from "../utils/validate-query";
import qs from "qs";
const userAssessmentUrl = "/assessments/user-assessments";
const userPersonalAssessmentUrl = "/user-assessments";
const userAssessmentCount = "/user-assessments/counts-by-status"

export const useUserAssesments = () => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${userAssessmentUrl}/`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        userAssessments: data?.data,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}

export const useUserAssesmentDetails = (id: string) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${userAssessmentUrl}/${id}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher);

    return {
        userAssessment: data,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}

export const useUserPersonalAssessments = (query: any) => {
    const filteredQuery = validateQuery(query)

    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${userPersonalAssessmentUrl}?${qs.stringify(filteredQuery)}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        userPersonalAssessments: data?.data,
        mutate,
        error,
        isLoading: !data? true : false,
        isError: error
    }
}

export const useUserPersonalAssesmentDetails = (id: string) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${userPersonalAssessmentUrl}/${id}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher);

    return {
        userPersonalAssessment: data?.data,
        mutate,
        error,
        isLoading: !data ? true : false,
        isError: error
    }
}

export const useUserAssesmentsCount = () => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${userAssessmentCount}/`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        userAssessmentsCount: data?.data,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}