import { Disclosure } from '@headlessui/react'
import { useEffect } from 'react'
import useAuth from '../context/useAuth'
import { useUserDetails } from '../hooks/users'
import defaultUserImage from './../images/default-user.png'
import { logoutSessionHandler } from './ProfileNav'

const MobileProfileNav = () => {
    const { user: userId } = useAuth()
    const {user, isLoading, mutate } = useUserDetails(userId)

    useEffect(() => {
        mutate()
    }, [mutate])

    if(isLoading) {
        return null
    }


    return (
        <>
            <div className="border-t border-gray-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                        <img
                            className="h-10 w-10 rounded-full"
                            src={defaultUserImage}
                            alt=""
                        />
                    </div>
                    <div className="ml-3">
                        <div className="text-base font-medium text-white">Candidate</div>
                        <div className="text-sm font-medium text-gray-400">{user.email}</div>
                    </div>
                    <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                        <span className="sr-only">View notifications</span>
                        {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                    </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                    <Disclosure.Button
                        as="a"
                        href="#"
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        onClick={logoutSessionHandler}
                    >
                        Sign out
                    </Disclosure.Button>
                </div>
            </div>

        </>
    )
}

export default MobileProfileNav