import { SandpackProvider } from "@codesandbox/sandpack-react"
import { useContext, useEffect } from "react";
import { checkFileExtension } from "../../utils/file-extension-checker";
import ConsolePreviewer from "./ConsolePreviewer";
import Editor from "./Editor";
import Previewer from "./Previewer";
import TestPreviewer from "./Tests/TestPreviewer";
import Parser from 'html-react-parser';
import Spinner from "../../ui/Spinner";
import { QuestionContext } from "../../routes/Questions";
import { secondToMinuteAndSecond } from "../../utils/time-converter";
import './demo.css';

interface EditorLayoutProps {
    handleChange?: any;
    values?: any
}

const EditorLayout = ({ handleChange, values }: EditorLayoutProps) => {
    const { question: questionData, isLoading: isLoadingQuestion, mutate: mutateQuestion, questionAnswer, mutateQuestionAnswer, isLoadingQuestionAnswer } = useContext(QuestionContext)
    const { title, durationInSeconds, statement, testCode, boilerplateCodes, options: settingsOptions, points, dependencies } = questionData;

    useEffect(() => {
        mutateQuestion()
        mutateQuestionAnswer()
    }, [mutateQuestion])

    useEffect(() => {
        window.onbeforeunload = function () {
            return true;
        };
        return () => {
            window.onbeforeunload = null;
        }
    }, []);

    if (isLoadingQuestion || isLoadingQuestionAnswer) {
        return <Spinner />
    }

    const parseDependencies = (dependencyArray: any[]) => {
        const dependencyObject: any = {}
        dependencyArray.map((dependency: any) => {
            dependencyObject[dependency.name] = dependency.version
        })
        return dependencyObject
    }

    const allFiles: any = {}
    const fileNames: any = []
    if (boilerplateCodes.length > 0) {
        boilerplateCodes.map((file: any, index: number) => {
            const fileName = "/" + file.fileName;
            fileNames.push(fileName)
            allFiles[fileName] = {
                code: file.code
            }
        })
    }

    allFiles["/index.test.js"] = { code: testCode, readOnly: true }

    const options: any = {
        showNavigator: true,
        showLineNumbers: true, // default - true
        showInlineErrors: true, // default - false
        editorHeight: 500, // default - 300
    }

    const customSetup: any = {
        environment: 'webpack',
        mode: 'tests',
        dependencies: parseDependencies([...dependencies, ...questionAnswer.dependencies]),
        autoRun: false,
    }

    fileNames.map((fileName: string) => {
        const result = checkFileExtension(fileName, "html")
        if (result) {
            customSetup['entry'] = result;
            customSetup['main'] = result
        } else return
    })

    const { minute, second } = secondToMinuteAndSecond(durationInSeconds);

    return (
        <>
            <SandpackProvider
                files={allFiles}
                template={settingsOptions.template === "vanilla-js" ? "vanilla" : settingsOptions.template}
                customSetup={customSetup}
                options={options}
            >
                <div className="px-5">
                    <div className="grid grid-cols-5 gap-4">
                        <div className="col-span-2 border rounded-sm py-5 px-2 h-[350px] overflow-y-auto scroll-smooth">
                            <h1 className="text-2xl font-normal pb-3">{title}</h1>
                            <span>Time: <strong>{minute}m {second > 0 ? `${second}s` : null}</strong></span>
                            <hr className="my-2" />
                            <p className="mt-2 overflow-y-auto">
                                <div id="statement"></div>
                                <div>{Parser(statement)}</div>
                            </p>
                        </div>
                        <div className="col-span-3 border rounded-sm">
                            <Editor showTabs showInlineErrors showLineNumbers wrapConent values={values} />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-1 sm:grid-cols-3 my-3 overflow-y-auto">
                        <div className="border rounded-sm h-[280px] overflow-y-auto">
                            <Previewer />
                        </div>

                        <div className="border rounded-sm h-[280px] overflow-y-auto">
                            <ConsolePreviewer />
                        </div>

                        <div className="relative border rounded-sm h-[280px] px-2 py-2 overflow-y-auto">
                            <TestPreviewer testFileName="/index.test.js" handleChange={handleChange} values={values} />
                        </div>
                    </div>
                </div>
            </SandpackProvider>
        </>
    )
}

export default EditorLayout;