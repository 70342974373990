import classnames from 'classnames'
interface BadgeProps {
    text: string;
    variant: 'error' | 'warning' | 'success' | 'info' | 'normal';
    showButton: boolean;
    onClick?: (el: any) => any;
    classNames?: string
}


const Badge = ({ text, variant, showButton, onClick, classNames }: BadgeProps) => {
    const variantsClassNames = {
        error: "inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800",
        warning: "inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800",
        success: "inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800",
        info: "inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800",
        normal: "inline-flex items-center border rounded-md px-2.5 py-0.5 text-sm font-medium text-black"
    }

    return (
        <span className={classnames(variantsClassNames[variant], classNames)} >
            {text}
            {showButton ? (<button
                type="button"
                className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
                onClick={onClick}
            >
                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
            </button>): null}
        </span>
    )
}

export default Badge;