import React from 'react'

interface ButtonProps extends React.HTMLProps<HTMLButtonElement>  {
    children: JSX.Element | string;
    type?: "button" | "submit"
}


const Button = ({ children, type, ...props }: ButtonProps) => {
  return (
    <button type={type} {...props}>
        {children}
    </button>
  )
}

export default Button