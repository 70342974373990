import { http } from "./http"

export const createDomain = async (body: any) => {
    return (await http.post(`/domains`, body))
}

export const deleteDomain = async (domainId: string) => {
    return (await http.delete(`/domains/${domainId}`))
}

export const updateDomain = async (body: any, domainId: string) => {
    return (await http.patch(`/domains/${domainId}`, body))
}

