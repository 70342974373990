import { Helmet } from 'react-helmet'
import RouteSetup from '../routes/RouteSetup';
import { mappedRoutes } from '../routes';
import useAuth from '../context/useAuth';
import NavbarV2 from './NavbarV2';

const Layouts = () => {
    const { isLoggedIn, userDetails } = useAuth()
    const sidebarNavigations = mappedRoutes.filter((navigation) => navigation.isSideNav === true && userDetails && navigation.permissions?.includes(userDetails.role))

    return (
        <>
            <Helmet>
                <title>Instant Hiring</title>
            </Helmet>
            <div>
                {isLoggedIn ?
                    <NavbarV2 sidebarNavigations={sidebarNavigations} />
                    : null
                }
                <div className="p-6">
                    <RouteSetup mappedRoutes={mappedRoutes} />
                </div>
            </div>
        </>
    )
}

export default Layouts