export const userNavigation = [
    // {
    //     name: 'Your Profile',
    //     href: '/profile',
    //     alt: 'profile',
    //     // icon: UserCircleIcon,
    // },
    // {
    //     name: 'Account Settings',
    //     href: '#',
    //     alt: 'settings',
    //     // icon: CogIcon
    // },
    { 
        name: 'Sign Out', 
        href: '#', 
        alt: 'logout', 
        // icon: LogoutIcon 
    },
]
