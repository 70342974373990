import { Route, Routes } from 'react-router-dom'
import PrivateRoute from '../components/auth-routes/PrivateRoute';
import PublicRoute from '../components/auth-routes/PublicRoute';

const RouteSetup = ({ mappedRoutes }: any) => {
  const renderRoutes = mappedRoutes.map((route: any, index: number) => {
    const Element = route.element;
    if(route.children && route.children.length > 0) {
      return route.children.map((subRoute: any, index: number) => {
        const Element = subRoute.element;
        return (
          <Route
            key={index}
            path={subRoute.path}
            element={
              <PrivateRoute roles={[...subRoute.permissions]}>
                <Element />
              </PrivateRoute>
            }
          />
        )
      })
    }


    if(route.permissions) {
      return (
        <Route
          key={index}
          path={route.path}
          element={
            <PrivateRoute roles={[...route.permissions]}>
              <Element />
            </PrivateRoute>
          }
        />
      )
    }

    if (route.protected === true) {
      return (
        <Route element={<PublicRoute />}>
          <Route
            key={index}
            path={route.path}
            element={<Element />}
          />
        </Route>
      )
    }
    return (
      <Route
        key={index}
        path={route.path}
        element={<Element />}
      />
    )
  })

  return (
    <Routes>
      {renderRoutes}
    </Routes>
  );
}

export default RouteSetup