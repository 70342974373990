import { FieldArray } from 'formik';
import { useEffect } from 'react'
import { useTopics } from '../../../hooks/topics';
import Badge from '../../../ui/Badge';
import { SelectField } from '../../../ui/SelectField';

export const TopicsField = ({ fields }: any) => {
    const { values, handleChange } = fields;
    const query: any = {};

    query['assessment'] = values.assessment ? values.assessment : "";
    const { topics, mutate: mutateTopics, isLoading: isTopicsLoading } = useTopics(query);

    useEffect(() => {
        mutateTopics()
    }, [mutateTopics])

    if (isTopicsLoading) return null;

    const topicsOption = topics.map((topic: any) => {
        return {
          value: topic.id,
          label: topic.title
        }
      });

    return (
        <>
            <SelectField
                options={topicsOption}
                onChange={(selectedOption: any) => {
                    handleChange("topics[0]")(selectedOption.value)
                }}
                name="topics[0]"
                placeholder="select topics"
            />
            <FieldArray name="topics">
                {({ insert, remove, push }) => (
                    <>
                        <div>
                            {values.topics!.map((topic: any, index: number) => {
                                const topicName = topics.find((el: any) => el.id === topic).title
                                return (
                                    <Badge key={index} text={topicName} variant='normal' showButton onClick={() => remove(index)} classNames="mr-1 mt-1" />
                                )
                            })}
                        </div>
                    </>
                )}
            </FieldArray>
        </>
    )
}
