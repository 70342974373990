import { secondToMinuteAndSecond } from "../../utils/time-converter";
import Parser from 'html-react-parser';

const McqPattern = ({ questionData, handleChange, values }: any) => {
    const { title, durationInSeconds, points, mcqAnswers, statement, options } = questionData;
    const { minute, second } = secondToMinuteAndSecond(durationInSeconds)
    return (
        <div className="px-4 mb-10">
            <h1 className="text-2xl font-normal pb-2">{title}</h1>
            <span>Time: <strong>{minute}m {second > 0 ? `${second}s` : null}</strong></span>
            <span className="ml-3">Points: <strong>{points}</strong></span>
            <div className="mt-10 mb-2">
                {Parser(statement)}
            </div>

            {options.hasMultipleAnswers ?
                <>
                    {mcqAnswers.map((mcq: any, index: number) => {
                        return (
                            <>
                                <div className="flex items-center mb-4" key={index}>
                                    <input
                                        id={`default-radio-${mcq.value}`} type="checkbox"
                                        name="mcqCandidateAnswers"
                                        className="w-4 h-4"
                                        onChange={handleChange}
                                        value={mcq.id}
                                    />
                                    <label htmlFor={`default-radio-${mcq.value}`} className="ml-2 text-sm font-medium text-black">{
                                        mcq.value
                                    }</label>
                                    <br />
                                </div>
                            </>
                        )
                    })}
                </>
                :
                <>
                    {mcqAnswers.map((mcq: any, index: number) => {
                        return (
                            <>
                                <div className="flex items-center mb-4" key={index}>
                                    <input
                                        id={`default-radio-${mcq.value}`} type="radio"
                                        name="mcqCandidateAnswers[0]"
                                        className="w-4 h-4"
                                        onChange={handleChange}
                                        value={mcq.id}
                                    />
                                    <label htmlFor={`default-radio-${mcq.value}`} className="ml-2 text-sm font-medium text-black">{
                                        mcq.value
                                    }</label>
                                    <br />
                                </div>
                            </>
                        )
                    })}
                </>}
        </div>
    )
}

export default McqPattern