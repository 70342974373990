import useSWR from "swr";
import useAxiosPrivate from "./useAxiosPrivate";
import config from './../config/default.json'
const questionAssessmentUrl = "/user-assessments/question-answer";

export const useQuestionAnswerDetails = (id: string) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${questionAssessmentUrl}/${id}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher);

    return {
        questionAnswer: data?.data,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}