import { SandpackTests, useSandpack } from "@codesandbox/sandpack-react"
import { PlayIcon } from "@heroicons/react/20/solid";
import { ErrorMessage, Field, FieldArray } from "formik";
import { useContext, useEffect, useState } from "react"
import { SubmitHandleContext } from "../../../routes/Questions";
import Modal from "../../../ui/Modal";
import TestData from "./TestData"
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import classNames from "classnames";
interface TestPreviewerProps {
    testFileName: string;
    handleChange?: any;
    values?: any;
}

const TestPreviewer = ({ testFileName, handleChange, values }: TestPreviewerProps) => {
    const { dispatch } = useSandpack();
    const [testResults, setTestResults] = useState([]);
    const dom: any = document.querySelector(".sp-c-pnsM");
    const testDom: any = document.querySelector(".sp-tests");
    const { showSubmitButton, setShowSubmitButton, openCodingQuestionModal, setOpenCodingQuestionModal } = useContext(SubmitHandleContext)

    values.testCasesCandidateAnswers = testResults.map((test: any) => {
        return {
            title: test.name,
            status: test.status === "pass" ? "passed" : "failed"
        }
    })
    useEffect(() => {
        if (dom) {
            dom.style.display = "none"
        }
        if (testDom) {
            testDom.style.display = "none"
        }
    }, [dom, testDom])

    return (
        <>
            <div>
                <p className="font-bold underline">Tests</p>
                {testResults.map((testResult, index) => {
                    return (
                        <TestData key={index} testResult={testResult} />
                    )
                })}
                <br />
                <SandpackTests watchMode={false} verbose onComplete={(data: any) => {
                    if (data[testFileName]["describes"] && data[testFileName]["describes"][""]) {
                        const tests: any = Object.values(data[testFileName]["describes"][""].tests);
                        setTestResults(tests)
                    }
                }} />

                <div id="run-test-button" className="absolute bottom-0 right-0 my-4 mx-3">
                    <div className="relative group flex flex-col w-32 mx-auto">
                        <div className="absolute -inset-1 bg-gradient-to-r from-blue-300 to-indigo-300 rounded-lg blur opacity-100 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-pulse" />
                        <button type="button" className="relative bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6 py-2 px-3"
                            onClick={() => {
                                dispatch({ type: "run-all-tests" });
                                setShowSubmitButton(true)
                            }}>
                            <div className="space-y-2">
                                <div className="flex">
                                    <PlayIcon className="h-4 w-4" />
                                    <span className="ml-1 font-bold">
                                        run all tests
                                    </span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

                <ReactTooltip
                    anchorId="run-test-button"
                    place="left"
                    content="submit button will appears after running all tests"
                />

                <Modal
                    title="Coding Challenge Submittion"
                    onClose={() => null}
                    state={openCodingQuestionModal}
                    action={
                        <>
                            <button
                                type="button"
                                form="question-form"
                                className={classNames("inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm", showSubmitButton? "bg-indigo-200 cursor-no-drop" : "bg-indigo-600 hover:bg-indigo-700")}
                                onClick={() => {
                                    dispatch({ type: "run-all-tests" });
                                    setShowSubmitButton(true)
                                }}
                                disabled={showSubmitButton}
                            >
                                run all test
                            </button>
                            {showSubmitButton ?
                                <button
                                    form="question-form"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm my-2"
                                    onClick={() => {
                                        setOpenCodingQuestionModal(false)
                                    }}
                                >
                                    Submit
                                </button> : null}
                        </>
                    }
                >
                    <p className="text-sm text-gray-500">
                        Hey your time is up
                    </p>

                </Modal>

            </div>
        </>
    )
}

export default TestPreviewer