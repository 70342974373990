import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { continueAssessment, startAssesment } from '../../services/assesments'
import Badge from '../../ui/Badge'
import Card from '../../ui/Card'
import Modal from '../../ui/Modal'

const AssementCard = ({ assessmentData }: any) => {
    const { id, title, requiredExperience, difficultyLevel, userAssessmentScore, userAssessmentStatus } = assessmentData;
    const [errorMessage, setErrorMessage] = useState<any>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)

    const navigate = useNavigate()
    let assesmentStatus = null;
    let assessmentButton = null;

    if (userAssessmentStatus && userAssessmentStatus === "completed") {
        assesmentStatus = <><Badge text={userAssessmentStatus} variant="success" showButton={false} /></>
    }
    if (userAssessmentStatus && userAssessmentStatus === "started") {
        assesmentStatus = <><Badge text={userAssessmentStatus} variant="warning" showButton={false} /></>;
        assessmentButton = <>
            <button
                type="button"
                className="inline-flex items-center rounded border border-transparent bg-yellow-400 px-2.5 py-1.5 text-xs font-medium text-black shadow-sm hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2 mt-2"
                onClick={async () => {
                    const response = await continueAssessment(id)
                    sessionStorage.setItem('userAssessment', JSON.stringify(response.data.userAssessment))
                    sessionStorage.setItem('questionAnswerId', response.data.nextQuestionAnswer.id);
                    navigate(`/questions/${response.data.nextQuestion.id}`)
                }}
            >
                Continue
            </button>
        </>
    }
    if (!userAssessmentStatus) {
        assesmentStatus = <><Badge text="not started yet" variant="error" showButton={false} /></>
        assessmentButton = <>
            <button
                type="button"
                className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-2"
                onClick={async () => {
                    try {
                        const response = await startAssesment(id)
                        sessionStorage.setItem('userAssessment', JSON.stringify(response?.data?.userAssessment))
                        sessionStorage.setItem('questionAnswerId', response.data.nextQuestionAnswer.id)
                        navigate(`/questions/${response?.data?.nextQuestion?.id}`)
                    }
                    catch (error: any) {
                        setOpenModal(true)
                        setErrorMessage(error?.response?.data?.message)
                        console.error(error)
                    }
                }}
            >
                Start
            </button>
        </>
    }

    return (
        <>
            <Card classNames='px-4 py-5'>
                <h1 className="font-normal text-2xl mb-2">{title}</h1>
                <p>Difficulty level: <strong>{difficultyLevel}</strong></p>
                <p>Required experience: <strong>{requiredExperience}</strong></p>
                <p>Score: <strong>{userAssessmentScore ? `${userAssessmentScore} points` : "0"}</strong></p>
                <p>Status: {assesmentStatus} </p>
                {assessmentButton}
            </Card>
            <Modal
                title="Opps!!"
                onClose={() => setOpenModal(false)}
                state={openModal}
                action={
                    <>
                        <button
                            form="question-form"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            onClick={() => {
                                setOpenModal(false)
                            }}
                        >
                            Got it!!
                        </button>
                    </>
                }
            >
                <p className="text-sm text-gray-500">
                    {errorMessage}
                </p>

            </Modal>
        </>
    )
}

export default AssementCard