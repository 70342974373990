import { TailSpin } from 'react-loader-spinner'
const Spinner = () => {
    return (
        <div className="flex w-full justify-center items-center h-screen overflow-hidden">
            <div className="m-auto">
                <TailSpin
                    height="50"
                    width="50"
                    color="#3240a8"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
                <span>Loading ...</span>
            </div>
        </div>
    )
}

export default Spinner