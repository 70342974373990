import { http } from "./http"

export const submitQuestionAnswer = async (assessmentId: string, questionId: string, body: any): Promise<any> => {
    return (await http.post(`/assessments/user-assessments/${assessmentId}/submit-answer/${questionId}`, body)).data
}

export const updateQuestion = async (body: any, questionId: string): Promise<any> => {
    return (await http.patch(`/questions/${questionId}`, body))
}

export const createQuestion = async (body: any) => {
    return (await http.post(`/questions`, body))
}

export const deleteQuestion = async (id: string) => {
    return (await http.delete(`/questions/${id}`))
}

export const deleteMcqAnswer = async (questionId:string, answerId:string) => {
    return (await http.delete(`/questions/${questionId}/mcq-answers/${answerId}`))
}

export const updateMcqAnswer = async (body: any, questionId:string, answerId:string) => {
    return (await http.patch(`/questions/${questionId}/mcq-answers/${answerId}`, body))
}

export const updateBoilerplateCode = async (body: any, questionId:string, boilerplateId:string) => {
    return (await http.patch(`/questions/${questionId}/boilerplate-codes/${boilerplateId}`, body))
}

export const deleteBoilerplateCode = async (questionId:string, boilerplateId:string) => {
    return (await http.delete(`/questions/${questionId}/boilerplate-codes/${boilerplateId}`))
}


export const updateTestCases = async (body: any, questionId:string, testcaseId:string) => {
    return (await http.patch(`/questions/${questionId}/test-cases/${testcaseId}`, body))
}

export const deleteTestCases = async (questionId:string, testcaseId:string) => {
    return (await http.delete(`/questions/${questionId}/test-cases/${testcaseId}`))
}

