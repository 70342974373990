import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../context/useAuth'
import { userNavigation } from '../utils/userNavigations'
import defaultUserImage from './../images/default-user.png'
import { useUserDetails } from './../hooks/users'

export const logoutSessionHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/"
}

const ProfileNav = () => {
    const { user: userId } = useAuth()
    const {user, isLoading, mutate } = useUserDetails(userId)

    useEffect(() => {
        mutate()
    }, [mutate])

    if(isLoading) {
        return null;
    }

    return (
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-app-color">
                    <span className="sr-only">Open user menu</span>
                    <img src={defaultUserImage} alt="default-user-image" width="40" height="40"/>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 z-10">
                    <div className="py-1 px-4">
                        <small className="text-gray-600">signed in as</small>
                        <span className="text-sm font-normal text-black-500 block">
                            {user.email}
                        </span>
                    </div>
                    <div className="py-1">
                        {userNavigation.map((item: any) => (
                            <Menu.Item key={item.name}>
                                {({ active }) => (
                                    <Link
                                        to={item.href}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700 hover:text-main-app-color'
                                        )}
                                        onClick={
                                            logoutSessionHandler
                                        }
                                    >
                                        <div className="flex">
                                            <span>
                                                {item.name}
                                            </span>
                                        </div>
                                    </Link>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ProfileNav
