import classNames from "classnames";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import CodingPattern from "../components/questions/CodingPattern";
import McqPattern from "../components/questions/McqPattern";
import Timer from "../components/Timer";
import { useQuestionAnswerDetails } from "../hooks/questionAnswer";
import { useQuestionDetails } from "../hooks/questions";
import { submitQuestionAnswer } from "../services/questions";
import Modal from "../ui/Modal";
import Spinner from "../ui/Spinner";
import { secondToMilisecond } from "../utils/time-converter";

export const QuestionContext = React.createContext<any>({});
export const SubmitHandleContext = React.createContext<any>({});

export default function Questions() {
    const { id: questionId } = useParams();
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState(false)
    const [openCodingQuestionModal, setOpenCodingQuestionModal] = useState<boolean>(false)
    const navigate = useNavigate()
    const userAssessmentData: any = sessionStorage.getItem('userAssessment');
    const questionAnswerId: any = sessionStorage.getItem('questionAnswerId')
    const userAssessment: any = JSON.parse(userAssessmentData)
    const { question, isLoading, mutate } = useQuestionDetails(questionId!);

    const { questionAnswer, isLoading: isLoadingQuestionAnswer, mutate: mutateQuestionAnswer } = useQuestionAnswerDetails(questionAnswerId ? questionAnswerId : "")

    const initialQuestionAnswerPayload = {
        testCasesCandidateAnswers: [],
        mcqCandidateAnswers: [],
        isTestCodeViewed: false,
        solution: ""
    }

    useEffect(() => {
        mutate()
        mutateQuestionAnswer()
    }, [mutate, mutateQuestionAnswer])

    if (isLoading || isLoadingQuestionAnswer) {
        return <Spinner />
    }

    return (
        <>
            <QuestionContext.Provider value={{ question, mutate, isLoading, questionAnswer, isLoadingQuestionAnswer, mutateQuestionAnswer }}>
                <Formik initialValues={initialQuestionAnswerPayload}
                    onSubmit={async (values) => {
                        console.log('triggred')
                        const response = await submitQuestionAnswer(userAssessment.id, question.id, values)
                        localStorage.removeItem("end_date");
                        if (response.data.isAssessmentCompleted === true) {
                            sessionStorage.removeItem('questionAnswerId');
                            setShowSubmitButton(false)
                            navigate('/assessments/complete')
                        }
                        else {
                            sessionStorage.setItem('questionAnswerId', response.data.nextQuestionAnswer.id);
                            setShowSubmitButton(false);
                            navigate(`/questions/${response.data.nextQuestion.id}`)
                        }
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                        } = props;

                        return (
                            <SubmitHandleContext.Provider value={{ showSubmitButton, setShowSubmitButton, openCodingQuestionModal, setOpenCodingQuestionModal }}>
                                <form
                                    id="question-form"
                                    onSubmit={handleSubmit}
                                >
                                    {question.type === "mcq" ? <McqPattern questionData={question} handleChange={handleChange} values={values} /> : <CodingPattern values={values} />}

                                    <div className="flex-1 px-4 flex justify-between">
                                        <div className="flex-1 flex">
                                            {
                                                question.type === "mcq" ? <button type="submit" className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                                                    disabled={isSubmitting}
                                                >
                                                    <div className="flex">
                                                        <span>
                                                            Submit answer
                                                        </span>
                                                        {isSubmitting ? <span className="ml-2 mt-1">
                                                                <TailSpin
                                                                    height="20"
                                                                    width="20"
                                                                    color="#000"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="1"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />

                                                            </span> : null}
                                                    </div>
                                                </button> : null
                                            }
                                            {showSubmitButton ? (
                                                <>
                                                    <button type="submit" className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                                                        disabled={isSubmitting}
                                                    >
                                                        <div className="flex">
                                                            <span>
                                                                Submit answer
                                                            </span>
                                                            {isSubmitting ? <span className="ml-2 mt-1">
                                                                <TailSpin
                                                                    height="20"
                                                                    width="20"
                                                                    color="#000"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="1"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />

                                                            </span> : null}
                                                        </div>
                                                    </button>
                                                    {question.type === "coding_challenge" ?
                                                        <small className="mt-3 ml-2">(Don't forget to run <strong>tests</strong> before submitting)</small> : null
                                                    }
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="ml-4 flex items-center md:ml-6">
                                            <div className="mt-2">
                                                Time:
                                                <strong className="ml-1">
                                                    <Timer questionType={question.type} durationInMs={secondToMilisecond(question.durationInSeconds)} setEvent={setOpenModal} setCodingEvent={setOpenCodingQuestionModal} />
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal
                                        title="MCQ Submittion"
                                        onClose={() => null}
                                        state={openModal}
                                        action={
                                            <>
                                                <button
                                                    form="question-form"
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                                    onClick={() => {
                                                        setOpenModal(false)
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </>
                                        }
                                    >
                                        <p className="text-sm text-gray-500">
                                            Hey your time is up
                                        </p>

                                    </Modal>
                                </form>
                            </SubmitHandleContext.Provider>
                        )
                    }}
                </Formik>
            </QuestionContext.Provider>
        </>
    )
} 