import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import Parser from 'html-react-parser';
import Badge from '../../ui/Badge';
import { toFixedWithNoRounding } from '../../utils/custom-math';
import { formatDateTime } from '../../utils/datetime-converter';

interface MCQAnswer {
    value: string;
    isCorrectAnswer: boolean;
    id: string;
}

interface MCQAnswersRenderer {
    answers: MCQAnswer[];
    userMcqAnswer: any;
}

const MCQAnswerRenderer = ({ answers, userMcqAnswer }: MCQAnswersRenderer) => {
    const userAnswer = userMcqAnswer[0];

    return (
        <>
            {answers.map((answer: MCQAnswer, index: number) => {
                return (
                    <dd key={index} className={classNames("mt-1 text-sm text-gray-900",
                        userAnswer?.value === answer?.id ? "font-bold" : "font-normal"
                    )}>
                        <div className="flex">
                            {answer.isCorrectAnswer ?
                                <CheckIcon className="h-4 w-4 text-green-600 mt-1" /> :
                                <XMarkIcon className="h-4 w-4 text-red-600 mt-1" />
                            }
                            <span className="ml-1">{answer?.value}</span>
                            {userAnswer?.value === answer?.id ? <span className="ml-1">(option you checked) </span> : null}

                        </div>
                    </dd>
                )
            })}
        </>
    )
}

const McqAnswers = ({ answer }: any) => {
    const statusColor: any = {
        correct: "success",
        incorrect: "error",
        partially_correct: "warning",
        none: "info"
    }

    const levelColor: any = {
        easier: "info",
        easy: "success",
        medium: "warning",
        harder: "error",
        hardest: "error"
    }

    const { status, points, comparativeScore, comparativeScoreInPercentage, remainingTimeInSeconds, startedAt, endedAt, totalTakenTimeInSeconds, mcqAnswers, question } = answer;

    return (
        <>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {status ? <Badge variant={statusColor[status]} text={status === "partially_correct" ? "partially correct": status} showButton={false} /> : <Badge variant="info" text="none" showButton={false} />}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Score</dt>
                    <dd className="mt-1 text-sm text-gray-900">{points ? points: "0"} pts</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Comparative score</dt>
                    <dd className="mt-1 text-sm text-gray-900">{toFixedWithNoRounding(comparativeScore)} pts</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Comparative score (%)</dt>
                    <dd className="mt-1 text-sm text-gray-900">{toFixedWithNoRounding(comparativeScoreInPercentage)}%</dd>
                </div>

                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Level</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        <Badge text={question?.difficultyLevel} variant={levelColor[question?.difficultyLevel]} showButton={false} />
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Time duration</dt>
                    <dd className="mt-1 text-sm text-gray-900">{question?.durationInSeconds} sec</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Started at</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {formatDateTime(startedAt)}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Ended at</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {endedAt ? formatDateTime(endedAt) : "-"}
                        </dd>
                </div>

                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Total taken time (sec)</dt>
                    <dd className="mt-1 text-sm text-gray-900">{totalTakenTimeInSeconds ? `${toFixedWithNoRounding(totalTakenTimeInSeconds)} sec` : "-"} </dd>
                </div>

                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Remaining time (sec)</dt>
                    <dd className="mt-1 text-sm text-gray-900">{remainingTimeInSeconds ? `${toFixedWithNoRounding(remainingTimeInSeconds)} sec` : "-"}</dd>
                </div>

                <div className="sm:col-span-3">
                    <dt className="text-sm font-medium text-gray-500">Statement</dt>
                    <dd className="mt-1 text-sm text-gray-900">{Parser(question?.statement)}</dd>
                </div>
                <div className="sm:col-span-3">
                    <dt className="text-sm font-medium text-gray-500">MCQ Answers</dt>
                    <MCQAnswerRenderer answers={question.mcqAnswers} userMcqAnswer={mcqAnswers} />
                </div>
            </dl>

        </>
    )
}

export default McqAnswers