import React from "react";
import Dashboard from "./Dashboard";
import Login from "./Login";
import NotAllowed from "./NotAllowed";
import NotFound from "./NotFound";
import Assessments from "./Assessments";
import Questions from "./Questions";
import QuestionList from "./admin/questions/QuestionList";
import Signup from "./Signup";
import Complete from "./Complete";
import TestEditor from "./TestEditor";
import { ROLE } from "../models/Role";
import CreateMCQ from "./admin/questions/CreateMCQ";
import CreateCodingChallenges from "./admin/questions/CreateCodingChallenges";
import EditQuestion from "./admin/questions/edit/EditQuestion";
import EditBoilerplates from "./admin/questions/edit/EditBoilerplates";
import EditTestCases from "./admin/questions/edit/EditTestCases";
import EditMcqAnswers from "./admin/questions/edit/EditMcqAnswers";
import EditTopics from "./admin/questions/edit/EditTopics";
import Topic from "./admin/topics/Topic";
import Skill from "./admin/skills/Skill";
import Domain from "./admin/domain/Domain";
import { UserAssessments } from "./UserAssessments";
import UserAssessmentDetails from "./UserAssessmentDetails";

export interface MappedRoutesInterface {
    label: string;
    element?: React.FC,
    protected?: boolean;
    path: string;
    exact?: boolean;
    private?: boolean;
    isSideNav?: boolean;
    isAdmin?: boolean;
    permissions?: ROLE[];
    children?: any;
}

export const mappedRoutes: MappedRoutesInterface[] = [
    {
        label: 'Login',
        element: Login,
        protected: true,
        path: '/'
    },
    {
        label: 'Sign up',
        element: Signup,
        protected: true,
        path: '/signup',
    },
    {
        label: 'Dashboard',
        element: Dashboard,
        path: '/dashboard',
        isSideNav: true,
        permissions: [
            ROLE.Admin,
            ROLE.Candidate
        ]
    },
    {
        label: "Assessments",
        element: Assessments,
        path: '/assessments',
        private: true,
        isSideNav: true,
        permissions: [
            ROLE.Admin,
            ROLE.Candidate
        ]
    },
    {
        label: "Complete",
        element: Complete,
        path: '/assessments/complete',
        permissions: [
            ROLE.Admin,
            ROLE.Candidate
        ]
    },
    {
        label: "Questions",
        element: Questions,
        path: '/questions/:id',
        permissions: [
            ROLE.Admin,
            ROLE.Candidate
        ]
    },
    {
        label: 'Not Found',
        element: NotFound,
        path: '*',
    },
    {
        label: 'Not Allowed',
        element: NotAllowed,
        path: '/not-allowed',
    },
    {
        label: "Test editor",
        element: TestEditor,
        path: '/test-editor/:questionId'
    },
    {
        label: "Questions",
        path: "",
        permissions: [
            ROLE.Admin,
        ],
        isSideNav: true,
        children: [
            {
                label: "Create MCQ",
                element: CreateMCQ,
                path: "/questions/create/mcq",
                permissions: [
                    ROLE.Admin,
                ],
            },
            {
                label: "Create Coding question",
                element: CreateCodingChallenges,
                path: "/questions/create/coding-challenges",
                permissions: [
                    ROLE.Admin,
                ],
            },
            {
                label: "All Questions",
                element: QuestionList,
                private: true,
                path: "/questions",
                permissions: [
                    ROLE.Admin,
                ],
            },
        ]
    },
    {
        label: "Edit Questions",
        element: EditQuestion,
        private: true,
        path: "/questions/edit/:id",
        permissions: [
            ROLE.Admin,
        ],
    },
    {
        label: "Edit Question Boilerplate",
        element: EditBoilerplates,
        private: true,
        path: "/questions/edit/:id/boilerplates",
        permissions: [
            ROLE.Admin,
        ],
    },
    {
        label: "Edit Questions",
        element: EditTestCases,
        private: true,
        path: "/questions/edit/:id/testcases",
        permissions: [
            ROLE.Admin,
        ],
    },
    {
        label: "Edit Questions",
        element: EditMcqAnswers,
        private: true,
        path: "/questions/edit/:id/mcq-answers",
        permissions: [
            ROLE.Admin,
        ],
    },
    {
        label: "Edit Topics",
        element: EditTopics,
        private: true,
        path: "/questions/edit/:id/topics",
        permissions: [
            ROLE.Admin,
        ],
    },
    {
        label: "Question Context",
        element: Topic,
        private: true,
        path: "/topic",
        isSideNav: true,
        permissions: [
            ROLE.Admin,
        ],
        children: [
            {
                label: "Topics",
                element: Topic,
                path: "/topics",
                permissions: [
                    ROLE.Admin,
                ],
            },
            {
                label: "Skills",
                element: Skill,
                path: "/skills",
                permissions: [
                    ROLE.Admin,
                ],
            },
            {
                label: "Domain",
                element: Domain,
                private: true,
                path: "/domains",
                permissions: [
                    ROLE.Admin,
                ],
            },
        ]
    },
    {
        label: "User all assessments",
        element: UserAssessments,
        private: true,
        path: "/user-assessments/:status",
        permissions: [
            ROLE.Candidate,
            ROLE.Admin,
        ],
    },
    {
        label: "User assessment details",
        element: UserAssessmentDetails,
        private: true,
        path: "/user-assessment/:id",
        permissions: [
            ROLE.Candidate,
            ROLE.Admin,
        ],
    },
]