import { AcademicCapIcon, CheckIcon, PlayIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useUserAssesmentsCount } from '../hooks/userAssessments'
import Spinner from '../ui/Spinner'

export default function DashboardContent() {
    const { userAssessmentsCount , isLoading, mutate } = useUserAssesmentsCount();
    
    useEffect(() => {
        mutate()
    }, [mutate]);

    if (isLoading) return <Spinner />;

    const allUserAssessment = userAssessmentsCount.find((userAssessment: any) => userAssessment.status === "all");
    const completedUserAssessment = userAssessmentsCount.find((userAssessment: any) => userAssessment.status === "completed");
    const runningUserAssessment = userAssessmentsCount.find((userAssessment: any) => userAssessment.status === "started");

    const stats = [
        { id: 1, name: 'Total assessments', stat: allUserAssessment?.count, icon: AcademicCapIcon, change: '122', params: 'all' },
        { id: 2, name: 'Total completed assessments', stat: completedUserAssessment? completedUserAssessment?.count : 0, icon: CheckIcon, change: '5.4%', params: 'completed' },
        { id: 3, name: 'Total running assessments', stat: runningUserAssessment ?runningUserAssessment?.count : 0, icon: PlayIcon, change: '3.2%', params: 'started' },
    ]

    return (
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {stats.map((item) => (
                    <div
                        key={item.id}
                        className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 border sm:px-6 sm:pt-6"
                    >
                        <dt>
                            <div className="absolute rounded-md bg-gray-700 p-3">
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    <Link to={`/user-assessments/${item.params}`}className="font-medium text-gray-600 hover:text-gray-500">
                                        {' '}
                                        View all<span className="sr-only"> {item.name} stats</span>
                                    </Link>
                                </div>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
