import { useEffect } from 'react'
import AssementCard from '../components/assessments/AssementCard'
import { useAssesments } from '../hooks/assessments'
import { useUserAssesments } from '../hooks/userAssessments'
import Spinner from '../ui/Spinner'
const Assessments = () => {
    const { userAssessments, isLoading, mutate } = useUserAssesments()
    useEffect(() => {
        mutate()
    }, [mutate])

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <h1 className="text-2xl font-semibold text-gray-900">Assessments</h1>
            <p>Want to prove your skill? Don't waste time and start your assesments given below</p>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                {userAssessments.map((assessment: any, index: number) => {
                    return (
                        <AssementCard key={index} assessmentData={assessment} />
                    )
                })}
            </div>
        </>
    )
}

export default Assessments;