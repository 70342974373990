import useSWR from "swr";
import useAxiosPrivate from "./useAxiosPrivate";
import config from './../config/default.json'
const assesmentUrl = "/assessments";

export const useAssesments = () => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${assesmentUrl}/`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher, config.swr);

    return {
        assesments: data?.data,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}

export const useAssesmentDetails = (id: string) => {
    const axiosPrivate = useAxiosPrivate()
    const fetcher = (input: any) => axiosPrivate.get(input).then(res => res.data)
    const fullUrl = `${assesmentUrl}/${id}`
    const { data, error, mutate, isValidating } = useSWR(fullUrl, fetcher);

    return {
        assesment: data,
        mutate,
        error,
        isLoading: !data,
        isError: error
    }
}