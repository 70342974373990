import Editor from '@monaco-editor/react';
import classNames from 'classnames';
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { useQuestionDetails } from '../../../../hooks/questions';
import { deleteBoilerplateCode, updateBoilerplateCode, updateQuestion } from '../../../../services/questions';
import Button from '../../../../ui/Button';
import Card from '../../../../ui/Card'
import InputBox from '../../../../ui/InputBox'
import Modal from '../../../../ui/Modal';
import Notification, { notifySuccess } from '../../../../ui/Notificaiton';
import Spinner from '../../../../ui/Spinner';
import { BoilerplateCodesEntity } from '../CreateCodingChallenges';
interface IBoilerplateCodes extends BoilerplateCodesEntity {
  _id: string
}
interface BoilerplateFormProps {
  boilerplate: IBoilerplateCodes;
  mutate?: any;
  questionId: string;
  questionData: any;
}

const EditBoilerplates = () => {
  const { id } = useParams();
  const { question: data, mutate, isLoading } = useQuestionDetails(id!)

  useEffect(() => {
    mutate()
  }, [mutate]);

  if (isLoading) return <Spinner />

  return (
    <>
      <h2 className="font-medium text-xl my-3">{data?.title}</h2>
      <h4 className="font-medium mb-2">Boilerplates ({data?.boilerplateCodes!.length})</h4>
      <div className="h-full overflow-y-auto">
        {data?.boilerplateCodes!.length > 0 &&
          data?.boilerplateCodes!.map((boilerplate: any, index: number) => {
            return (
              <BoilerplateForm key={index} boilerplate={boilerplate} mutate={mutate} questionId={id!} questionData={data} />
            )
          })}
      </div>
      <Button
        type="button"
        className="border rounded-md px-3 mt-1 py-1 bg-indigo-600 text-white"
        onClick={async () => {
          const res = await updateQuestion({
            boilerplateCodes: [
              { fileName: `${(Math.random() + 1).toString(36).substring(7)}.js`, code: "//write your code", extension: 'js' }
            ]
          }, data?.id)
          mutate()
        }}
      >
        + Add boilerplate file
      </Button>
      <Notification />
    </>
  )
}


const BoilerplateForm = ({ boilerplate, mutate, questionId, questionData }: BoilerplateFormProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [idx, setIdx] = useState<number>();
  const editorRef = useRef<any>(null);

  const initialBoilerplateValue: BoilerplateCodesEntity =
  {
    fileName: boilerplate.fileName,
    code: boilerplate.code,
    extension: boilerplate.extension
  };

  const formik = useFormik({
    initialValues: initialBoilerplateValue,
    onSubmit: async (values) => {
      const res = await updateBoilerplateCode(values, questionId, boilerplate._id)
      if (res.status === 200) {
        notifySuccess('Boilerplate code is updated successfully')
      }
    }
  })

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
  }

  const { values, handleChange, setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Card classNames="mb-2 px-2 py-3 w-2/5">
          <div className="grid grid-cols-3 gap-2">
            <div className="col">
              <InputBox
                name={`fileName`}
                placeholder="Enter filename"
                type="text"
                value={values.fileName}
                onChange={handleChange}
              />
            </div>
            <div className="col text-center">
              {!boilerplate.code ? (
                <Button
                  type="button"
                  className="border rounded-md bg-indigo-600 text-white px-4 py-1.5"
                  onClick={() => {
                    setCode(boilerplate.code)
                    setOpenModal(true)
                  }}
                >
                  add code
                </Button>
              ) : (
                <Button
                  type="button"
                  className="border rounded-md bg-indigo-600 text-white px-4 py-1.5"
                  onClick={() => {
                    setCode(boilerplate.code)
                    setOpenModal(true)
                  }}
                >
                  view code
                </Button>
              )}
            </div>
            <div className="flex col text-right">
              <Button type="submit"
                  className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                  disabled={isSubmitting}
                  height="10%">
                  <div className="flex">
                    <span>
                      Update
                    </span>
                    {isSubmitting ?
                      <span className="ml-2 mt-1">
                        <TailSpin
                          height="20"
                          width="20"
                          color="#000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </span>
                      : null}
                  </div>
              </Button>

              <Button
                type="button"
                className="border rounded-md bg-red-600 text-white px-4 py-1.5"
                onClick={async () => {
                  const updatedBoilerplateCodes= questionData.boilerplateCodes.filter((boilerplateCode: any) => boilerplateCode.id !== boilerplate._id)
                  await deleteBoilerplateCode(questionId, boilerplate._id);
                  mutate({ ...questionData, testCases: updatedBoilerplateCodes })
                }}
              >
                remove
              </Button>
            </div>
          </div>
        </Card>
        <Modal
          title={`view boilerplate file`}
          onClose={() => { }}
          state={openModal}
          action={
            <>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-">
                <button
                  className="rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm mr-2"
                  onClick={() => {
                    setFieldValue(`code`, editorRef.current?.getValue())
                    setOpenModal(false)
                  }}
                >
                  save
                </button>
                <button
                  form="question-form"
                  className="rounded-md border border-transparent bg-red-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                  onClick={() => {
                    setOpenModal(false)
                    setCode('')
                    setIdx(0)
                  }}
                >
                  No
                </button>
              </div>
            </>
          }
        >
          <div className="text-sm text-gray-500">
            <Editor
              height="40vh"
              defaultLanguage="javascript"
              defaultValue={code ? code : "//write your code"}
              onMount={handleEditorDidMount}
              className="border rounded md"
            />
          </div>
        </Modal>
      </form>
    </>
  )
}

export default EditBoilerplates