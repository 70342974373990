interface TableProps {
    tableHeaders: JSX.Element;
    tableBody: JSX.Element;
    filterActions?: JSX.Element;
    title?: string;
    tagLine?: string;
}

export default function Table({ title, tagLine, tableHeaders, tableBody, filterActions }: TableProps) {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {tagLine}
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                   {filterActions ? filterActions : null}
                </div>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            {tableHeaders}
                            {tableBody}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
