import { useFormik } from 'formik'
import { useState } from 'react'
import Pagination from '../../../components/Pagination'
import { deleteDomain, updateDomain } from '../../../services/domains'
import Button from '../../../ui/Button'
import InputBox from '../../../ui/InputBox'
import Modal from '../../../ui/Modal'
import Table from '../../../ui/Table'

const TableHeaders = () => {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
        >
          Title
        </th>

        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
        </th>
      </tr>
    </thead>
  )
}

const DomainForm = ({ domain, extendedAction, mutate, modalConfigForForm }: any) => {
  const initialDomainValue: any =
  {
    title: domain?.title,
  };

  const { setOpenEditModal, setSelectedDomain } = modalConfigForForm;

  const formik = useFormik({
    initialValues: initialDomainValue,
    onSubmit: async (values, helper) => {
      try {
        await updateDomain(values, domain?.id)
        setOpenEditModal(false)
        setSelectedDomain({})
        mutate()
      }
      catch (error: any) {
        console.error(error)
      }

    }
  });
  const { values, handleChange, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-span-1 sm:col-span-1">
        <InputBox
          id="domain-name"
          name={`title`}
          placeholder="Enter domain name"
          type="text"
          value={values.title}
          onChange={handleChange}
          required
        />
      </div>

      <div className="flex justify-center">
        <Button type="submit" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer mt-5" height="10%">
          Submit
        </Button>
        {extendedAction}
      </div>
    </form>
  )
}


const TableBody = ({ data, mutate }: any) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedDomain, setSelectedDomain] = useState<any>({})

  return (
    <>
      <tbody className="divide-y divide-gray-200">
        <>
          {data?.map((domain: any) => {
            return (
              (
                <tr key={domain?.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                    {domain?.title}
                  </td>


                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                    <button className="border rounded-md px-3 bg-indigo-600 text-white hover:bg-indigo-700" onClick={() => {
                      setOpenEditModal(true)
                      setSelectedDomain(domain)
                    }}>
                      edit<span className="sr-only">, edit</span>
                    </button>
                    <button className="border rounded-md px-3 bg-red-600 text-white hover:bg-red-700" onClick={() => {
                      setOpenDeleteModal(true)
                      setSelectedDomain(domain)
                    }}>
                      Delete<span className="sr-only">, Delete</span>
                    </button>
                  </td>
                </tr>
              )
            )
          })}
        </>
      </tbody>

      <Modal
        title={`Want to Edit ${selectedDomain?.title} domain?`}
        onClose={() => {
          setOpenEditModal(false)
          setSelectedDomain({})
        }}
        state={openEditModal}
      >
        <DomainForm
          domain={selectedDomain}
          extendedAction={<>
            <Button type="button" onClick={() => {
              setOpenEditModal(false)
              setSelectedDomain({})
            }} className="ml-2 px-3 py-2 bg-red-600 text-white rounded-md cursor-pointer mt-5" height="10%">
              close
            </Button></>
          }
          mutate={mutate}
          modalConfigForForm={{ setOpenEditModal, setSelectedDomain }}
        />
      </Modal>

      <Modal
        title={`Want to delete ${selectedDomain?.title} domain?`}
        onClose={() => {
          setOpenDeleteModal(false)
          setSelectedDomain({})
        }}
        state={openDeleteModal}
        action={
          <>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-">
              <button
                className="rounded-md border border-transparent bg-green-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm mr-2"
                onClick={async () => {
                  try {
                    const newDomainList = data.filter((domain: any) => selectedDomain.id !== domain.id)
                    await deleteDomain(selectedDomain.id)
                    mutate([...newDomainList])
                    setOpenDeleteModal(false)
                  }
                  catch(error: any) {
                    console.error(error)
                  }
                }}
              >
                yes
              </button>
              <button
                form="question-form"
                className="rounded-md border border-transparent bg-red-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                onClick={() => {
                  setOpenDeleteModal(false)
                  setSelectedDomain({})
                }}
              >
                No
              </button>
            </div>
          </>
        }
      >
      </Modal>
    </>
  )
}

const DomainList = ({ assessments, domains, mutate, pagination }: any) => {
  const { meta, setPage, page } = pagination;
  return (
    <div>
      {domains?.length > 0 ? (
        <Table tableHeaders={<TableHeaders />} tableBody={<TableBody data={domains} mutate={mutate} assessments={assessments} />} />
      ) : (
        <>
          <Table tableHeaders={<TableHeaders />} tableBody={<TableBody data={domains} mutate={mutate} />} />
          <p className="flex flex-col items-center my-40 font-bold text-xl">
            No data available
          </p>
        </>
      )}
      <Pagination meta={meta} setPage={setPage} page={page} />
    </div>
  )
}

export default DomainList