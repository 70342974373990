import { Dialog, Transition } from '@headlessui/react';
import classnames from 'classnames';
import React, { Fragment } from 'react';

export interface DrawerProps extends React.HTMLProps<HTMLDivElement> {
	children?: React.ReactNode;
	actions?: React.ReactNode;
	state?: boolean;
	onClose: (value: boolean) => void;
	outsideAccess?: boolean;
}

interface DrawerActionsProps {
	actions: React.ReactNode;
	className: string;
}
const DrawerActions = ({ className, actions }: DrawerActionsProps) => {
	return (
		<div
			className={classnames(
				'flex fixed p-4 left-0 w-full bottom-0 border-t border-gray-300 h-16 items-center',
				className
			)}
		>
			{actions}
		</div>
	);
};
const Drawer = ({ children, state, onClose, outsideAccess, actions }: DrawerProps) => {
	return (
		<Transition.Root show={!!state} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => (!outsideAccess ? onClose(false) : null)}>
				{!outsideAccess ? <div className="fixed inset-0" /> : null}
				<div className={classnames(outsideAccess ? '' : 'fixed inset-0',)}>
					<div className="absolute inset-0 bg-white">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 lg:pl-160 md:mt-14">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-300 sm:duration-300"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-200 sm:duration-200"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-none">
									<div className="flex h-full flex-col overflow-y-auto px-4 shadow-xl border-l border-gray-300">
										<div className="relative w-full h-full flex flex-col z-10">
											{children}

											{actions ? (
												<DrawerActions className="absolute justify-between z-10 bg-white" actions={actions} />
											) : (
												''
											)}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default Drawer;