import classNames from 'classnames';
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { useQuestionDetails } from '../../../../hooks/questions';
import { useTopics } from '../../../../hooks/topics';
import { updateQuestion } from '../../../../services/questions';
import Badge from '../../../../ui/Badge';
import Button from '../../../../ui/Button';
import ComboBox from '../../../../ui/ComboBox';
import Notification, { notifySuccess } from '../../../../ui/Notificaiton';
import Spinner from '../../../../ui/Spinner';
import { McqAnswersEntity } from '../CreateMCQ';

interface IMcqAnswer extends McqAnswersEntity {
    id: string
}
interface McqAnswersFormProps {
    mcqAnswer: IMcqAnswer;
    mutate?: any;
    questionId: string;
    questionData: any;
}

const EditTopics = () => {
    const { id } = useParams();
    const { question: data, mutate, isLoading } = useQuestionDetails(id!)
    const [topicName, setTopicName] = useState<string>("");
    const query: any = {}
    query['assessment'] = data?.assessment;
    const { topics, mutate: mutateTopics, isLoading: isTopicsLoading } = useTopics(query)

    useEffect(() => {
        mutate()
        mutateTopics()
    }, [mutate, mutateTopics]);

    if (isLoading || isTopicsLoading) return <Spinner />;

    return (
        <>
            <h2 className="font-medium text-xl my-3">{data?.title}</h2>
            <h4 className="font-medium mb-2">Topic Name ({data?.topics[0]?.title}) </h4>
            <div>
                <TopicForm assessment={data?.assessment} mutate={mutate} questionData={data} questionId={data?.id} topics={topics} selectedTitle={data?.topics} />
                {/* {data?.topics!.length > 0 &&
                    data?.topics!.map((topic: any, index: number) => {
                        return (
                            <Badge key={index} text={topic.title} variant='normal' showButton={false} classNames="mr-1 mt-1" />
                        )
                    })} */}
            </div>
            <Notification />
        </>
    )
}

const TopicForm = ({ mutate, questionId, topics }: any) => {
    const initialValues: { topics: string[] } =
    {
        topics: []
    };


    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values, helper) => {
            try {
                const res = await updateQuestion({
                    topics: [...values.topics]
                }, questionId)
                if (res.status === 200) {
                    notifySuccess("Topic updated successfully")
                    mutate()
                    helper.resetForm()
                }
            }
            catch (error: any) {
                console.error(error)
            }
        }
    })

    const { values, handleChange, handleSubmit, isSubmitting } = formik;
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="w-2/5 grid grid-cols-2 gap-4" >
                    <div className="col-span-1">
                        <ComboBox
                            name={"topics[0]"}
                            items={
                                !topics ? [] : topics.map(({ title, assessment }: any) => ({
                                    key: title,
                                    value: { title, assessment }
                                }))
                            }
                            displayValue={(el: any) => el?.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-span-1">
                        <Button type="submit"
                            className={classNames("px-3 py-1.5 mt-1 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                            disabled={isSubmitting}
                            height="10%">
                            <div className="flex">
                                <span>
                                    Update
                                </span>
                                {isSubmitting ?
                                    <span className="ml-2 mt-1">
                                        <TailSpin
                                            height="20"
                                            width="20"
                                            color="#000"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    </span>
                                    : null}
                            </div>
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default EditTopics