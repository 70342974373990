import { useEffect, useState } from "react";
import Countdown from "react-countdown";

export default function Timer({ questionType, durationInMs, setEvent, setCodingEvent }: any) {
  const [data, setData] = useState(
    { date: Date.now(), delay: durationInMs }
  );

  const Completionist = () => <span className="ml-1 font-normal">Time up!!</span>;

  const renderer = ({ minutes, seconds, completed }: any) => {
    if(questionType === "coding_challenge") {
      setCodingEvent(completed ? true : false)
    } else {
      setEvent(completed ? true : false)

    }
    if (completed) {
      // Render a complete state
      // localStorage.removeItem('end_date')
      // onEvent
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}m {seconds}s
        </span>
      );
    }
  };

  const wantedDelay = durationInMs; //10 ms

  const getLocalStorageValue = (key: any) => localStorage.getItem(key);

  useEffect(() => {
    const savedDate: any = getLocalStorageValue("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear uour saved end date
        const data: any = localStorage.getItem("end_date")
        if (data.length > 0) {}
          // localStorage.removeItem("end_date");
      } else {
        //No update the end date with the current date
        setData({ date: currentTime, delay: delta });
      }
    }
  }, []);

  return (
    <>
      <Countdown date={data.date + data.delay} renderer={renderer}
        onStart={(delta) => {
          //Save the end date
          if (localStorage.getItem("end_date") == null)
            localStorage.setItem(
              "end_date",
              JSON.stringify(data.date + data.delay)
            );
        }}
        onComplete={() => {
          if (localStorage.getItem("end_date") != null) {}
            // localStorage.removeItem("end_date");
        }}
      />
    </>
  )
}