import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react'
import { useAssesments } from '../../../hooks/assessments';
import { useTopics } from '../../../hooks/topics';
import { createTopics } from '../../../services/topics';
import Alert from '../../../ui/Alert';
import Button from '../../../ui/Button';
import InputBox from '../../../ui/InputBox'
import { SelectField } from '../../../ui/SelectField';
import Spinner from '../../../ui/Spinner';
import TopicList from './TopicList';

const Topic = () => {
    const { assesments, isLoading: isAssessmentsLoading, mutate: mutateAssessments } = useAssesments();
    const [page, setPage] = useState<number>(1)
    const [errors, setErrors] = useState<string[]>([])
    const [searchContext, setSearchContext] = useState<string>("")
    const [assessment, setAssessment] = useState<string[]>([]);
    const [filterCount, setFilterCount] = useState<number>(0)

    const query: any = {}
    query['limit'] = 5;
    query['page'] = page;
    query['searchTerm'] = searchContext;
    query['assessment'] = assessment;
    let timeOutId: any;

    const { topics, isLoading: isTopicsLoading, mutate: mutateTopics, meta } = useTopics(query);

    useEffect(() => {
        mutateAssessments()
        mutateTopics()
    }, [mutateAssessments, mutateTopics])

    const initialMcqValue: any =
    {
        topics: [],
        assessment: ""
    };

    const handleSearch = (event: any) => {
        if (timeOutId) { clearTimeout(timeOutId) }
        timeOutId = setTimeout(() => { setSearchContext(event.target.value) }, 500)
    }

    const handleAssessment = (event: any) => {
        setAssessment(event.target.value)
        mutateTopics()
    }

    const formik = useFormik({
        initialValues: initialMcqValue,
        onSubmit: async (values, helper) => {
            try {
                helper.resetForm();
                mutateTopics([...topics, values])
                await createTopics(values)
                mutateTopics()
            }
            catch (error: any) {
                if (error.response.status === 400) {
                    setErrors(error.response.data.errorMessages)
                }
                console.error(error)
            }
        }
    });

    if (isAssessmentsLoading || isTopicsLoading) return <Spinner />;
    const { values, handleChange, handleSubmit, setFieldValue } = formik;
    const assessmentOptions = assesments.map((assessment: any) => {
        return {
            value: assessment.id,
            label: assessment.title
        }
    });

    return (
        <>
            <div className="flex justify-left ml-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-1 sm:col-span-1">
                            <label htmlFor="topic-name" className="block text-sm font-medium text-gray-700">
                                Topics name
                            </label>
                            <InputBox
                                id="topic-name"
                                name={`topics[0]`}
                                placeholder="Enter topic name"
                                type="text"
                                value={values.topics}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="col-span-1 sm:col-span-1">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Assessments
                            </label>
                            <SelectField
                                options={assessmentOptions}
                                defaultValue={assessmentOptions.find((option: any) => values.assessment === option.value)}
                                onChange={(selectedOption: any) => {
                                    setFieldValue('assessment', selectedOption.value)
                                }}
                                placeholder="select assessment"
                                hideDefaultButton
                            />
                        </div>

                        <div className="col-span-1 sm:col-span-1">
                            <Button type="submit" className="px-3 py-1.5 bg-indigo-600 text-white rounded-md cursor-pointer mt-5" height="10%">
                                Create
                            </Button>
                        </div>
                    </div>
                </form>

                <div className="col-span-1 sm:col-span-1 ml-auto">
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Assessments (filtering)
                    </label>
                    <SelectField
                        options={assessmentOptions}
                        defaultValue={assessmentOptions.find((option: any) => assessment === option.value)}
                        onChange={(selectedOption: any) => {
                            setAssessment(selectedOption.value)
                            setFilterCount((prev: any) => prev + 1)
                        }}
                        placeholder="select assessment"
                        hideDefaultButton
                    />
                </div>
                <div className='ml-2'>
                    <div className="col-span-1 sm:col-span-1 mt-5">
                        <InputBox
                            autoFocus
                            name={`searchContext`}
                            placeholder="search"
                            type="search"
                            defaultValue={searchContext}
                            onChange={handleSearch}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className='flex justify-left ml-8'>
                {errors.length > 0 ? (
                    <>
                        <Alert variant='error' message={
                            <>
                                <h4 className="font-semibold">Errors</h4>
                                <ul className="list-disc ml-3">
                                    {errors.map((error: any) => {
                                        return <li>{error}</li>
                                    })}
                                </ul>
                            </>
                        } />
                    </>
                ) : null}
            </div>
            <div>
                <TopicList assessments={assesments} topics={topics} mutate={mutateTopics} pagination={{ meta, page, setPage }} />
            </div>
        </>
    )
}

export default Topic