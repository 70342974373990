import Select from 'react-select';
const brandColor = '#5856d6';
const customStyles: any = {
    control: (base: any, state: any) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        border: state.isFocused
            ? "1px solid" + brandColor
            : "1px soild #cccccc",
        '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
        }
    }),
    option: (base: any, state: any) => {
        return {
            ...base,
            backgroundColor: state.isFocused ? brandColor : null,
            color: state.isFocused ? "#ffffff" : "#000000"
        };
    }
};

interface SelectOption {
    value: string;
    label: string;
}

interface SelectFieldProps {
    options: SelectOption[];
    defaultValue?: any;
    onChange: (el: any) => void;
    name?: string;
    placeholder?: string;
    hideDefaultButton?: boolean
}

export const SelectField = ({ options, defaultValue, onChange, name, placeholder, hideDefaultButton }: SelectFieldProps) => {
    let defaultButtons: any;
    if(hideDefaultButton === true) defaultButtons =  { IndicatorSeparator:() => null }
    else defaultButtons = {}

    return (
        <Select
            styles={customStyles}
            value={defaultValue? defaultValue : ""} 
            options={options} 
            onChange={onChange} 
            name={name} 
            placeholder={placeholder}
            components={defaultButtons}
        />
    )
}
