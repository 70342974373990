import { useContext } from 'react'
import { useEffect, createContext, useState, useMemo } from 'react'
import jwt_decode from "jwt-decode";
import useAxiosPrivate from '../hooks/useAxiosPrivate';

interface AuthContextInterface {
    isLoggedIn: boolean;
    user: string;
    userDetails: any;
}
export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface)

export function AuthProvider({ children }: any) {
    // this cookie related code will be removed soon
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const [userDetails, setUserDetails] = useState<any>({})
    const http = useAxiosPrivate()
    const accessToken: any = localStorage.getItem('accessToken')

    let decodedData: any;
    if (accessToken) {
        decodedData = jwt_decode(accessToken);
    }

    async function getUser() {
        try {
            const response = await http.get(`/users/${decodedData?.id}`)
            setUserDetails(response.data.data)
            setIsLoggedIn(true)

        } catch (error) {
            console.error(error)
            setIsLoggedIn(false)
        }
    }

    useEffect(function () {
        getUser();
    }, [])

    const memoedValue = useMemo(
        () => ({
            isLoggedIn,
            user: decodedData ? decodedData.id : null,
            userDetails: userDetails
        }),
        [isLoggedIn, userDetails]
    )

    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext)
}

export { useAuth }
