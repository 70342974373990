import classNames from 'classnames';
import Drawer from '../../../ui/Drawer'

interface PreviewQuestionProps {
  state: boolean;
  onClose: (value: boolean) => void;
  actions?: React.ReactNode;
  previewTitle?: string;
  preview: React.ReactNode;
  innerStyle?: "border-dashed" | "normal" | "border" | undefined
}

const PreviewQuestion = ({ state, onClose, actions, preview, previewTitle, innerStyle }: PreviewQuestionProps) => {

  const innerStyleClasses = {
    border: {
      className: "border-2 border border-gray-300 rounded-md"
    },
    "border-dashed": {
      className: "border-2 border-dashed border-gray-300 rounded-md"
    },
    normal: {
      className: ""
    }
  }

  return (
    <Drawer state={state} onClose={onClose} actions={actions}
    >
      <h2 className="text-2xl font-medium my-3">{previewTitle}</h2>
      <div className="">
        <div className={classNames("h-[610px] overflow-y-auto", innerStyleClasses[innerStyle!]?.className)}>
          {preview}
        </div>
      </div>
    </Drawer>
  )
}

export default PreviewQuestion