import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import ProfileNav from "./ProfileNav";
import logo from './../images/logo.png'
import MobileProfileNav from "./MobileProfileNav";
import SubItem from "./SubItem";
import React from "react";

export default function NavbarV2({ sidebarNavigations }: any) {
    const location = useLocation()
    return (
        <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
            {({ open }) => (
                <>
                    <div className="mx-auto px-6">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <Link to="/dashboard">
                                        <img
                                            className="block h-8 w-auto lg:hidden"
                                            src={logo}
                                            alt="Your Company"
                                        />
                                    </Link>
                                    <Link to="/dashboard">
                                        <img
                                            className="hidden h-8 w-auto lg:block"
                                            src={logo}
                                            alt="Your Company"
                                        />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">

                                        {sidebarNavigations.map((navigation: any, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {!navigation.children ? (
                                                        <Link
                                                            key={index}
                                                            to={navigation.path}
                                                            className={classNames(
                                                                navigation.path === location.pathname
                                                                    ? 'bg-gray-900 text-white'
                                                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'rounded-md px-3 py-2 text-sm font-medium text-white'
                                                            )}
                                                        >
                                                            {navigation.label}
                                                        </Link>
                                                    ) : (
                                                    <>
                                                        <SubItem label={navigation.label} subMenuItems={navigation.children} path={navigation.path} />
                                                    </>
                                                    )}
                                                    
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                    >
                                        <span className="sr-only">View notifications</span>
                                        {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                                    </button>
                                    <ProfileNav />
                                </div>
                            </div>
                            <div className="-mr-2 flex sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <svg className="w-6 h-6 stroke-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                                    ) : (
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" /></svg>
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pt-2 pb-3">
                            {sidebarNavigations.map((navigation: any, index: number) => (
                                <React.Fragment key={index}>
                                    <Disclosure.Button
                                        as="a"
                                        href={navigation.path}
                                        className={classNames(
                                            navigation.path === location.pathname
                                                ? 'bg-gray-900 text-white'
                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium text-white'
                                        )}
                                    >
                                        {navigation.label}
                                    </Disclosure.Button>
                                </React.Fragment>
                            ))}
                        </div>
                        <MobileProfileNav />
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}