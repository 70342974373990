import { Field, FieldArray, Formik } from 'formik'
import { PersistFormikValues } from 'formik-persist-values';
import { useEffect, useRef, useState } from 'react'
import Button from '../../../ui/Button';
import InputBox from '../../../ui/InputBox';
import Card from '../../../ui/Card';
import Modal from '../../../ui/Modal';
import Editor from "@monaco-editor/react";
import { createQuestion } from '../../../services/questions';
import { useAssesments } from '../../../hooks/assessments';
import CodingPattern from '../../../components/questions/CodingPattern';
import { QuestionContext } from '../../Questions';
import Notification, { notifySuccess } from '../../../ui/Notificaiton';
import PreviewQuestion from './PreviewQuestion';
import Alert from '../../../ui/Alert';
import classNames from 'classnames';
import { TailSpin } from 'react-loader-spinner';
import TextEditor from '../../../ui/text-editor/TextEditor';
import { useDomains } from '../../../hooks/domains';
import { useSkills } from '../../../hooks/skills';
import { SelectField } from '../../../ui/SelectField';
import { TopicsField } from './TopicsField';

export enum EnumQuestionStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  TestCodeAdded = 'test_code_added',
}

export interface ICodingChallenges {
  assessment: string;
  title: string;
  statement: string;
  type: string;
  domain: string;
  skill: string;
  status: EnumQuestionStatus;
  difficultyLevel: string;
  durationInSeconds: number;
  isTestCodeViewable: boolean;
  codingChallengeQuestionOptions: CodingChallengeQuestionOptions;
  testCases?: (TestCasesEntity)[] | null;
  boilerplateCodes?: (BoilerplateCodesEntity)[] | null;
  testCode: string;
  dependencies?: (DependenciesEntity)[] | null;
  topics?: (string)[] | null;
  domainTitle: string;
  skillTitle: string;
}
export interface CodingChallengeQuestionOptions {
  programmingLanguage: string;
  template: string;
  challengeType: string;
  solution: string;
}
export interface TestCasesEntity {
  title: string;
  points: number;
}
export interface BoilerplateCodesEntity {
  fileName: string;
  code: string;
  extension: string;
}
export interface DependenciesEntity {
  name: string;
  version: string;
  isDeletable: boolean;
}

enum DifficultyLevel {
  Easiest = "easiest",
  Easy = "easy",
  medium = "easiest",
  Harder = "Harder",
  Hardest = "Hardest",
}

const CreateCodingChallenges = () => {
  const codingChallengeData = JSON.parse(localStorage.getItem("coding-challege-question-payload")!)
  if (codingChallengeData) {
    codingChallengeData["options"] = codingChallengeData.codingChallengeQuestionOptions
  }

  const { assesments, isLoading, mutate } = useAssesments();
  const [open, setOpen] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [idx, setIdx] = useState<number>();
  const editorRef = useRef<any>(null);
  const [errors, setErrors] = useState<string[]>([])
  const [globalError, setGlobalError] = useState<string>('')
  const query: any = {};
  const { domains, mutate: mutateDomains, isLoading: isDomainsLoading } = useDomains(query);
  const { skills, mutate: mutateSkills, isLoading: isSkillsLoading } = useSkills(query);

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
  }

  const initialCodingChallengesValue: ICodingChallenges =
  {
    assessment: '',
    title: '',
    statement: '',
    type: 'coding_challenge',
    domain: '',
    skill: '',
    difficultyLevel: DifficultyLevel.Easy,
    durationInSeconds: 0,
    status: EnumQuestionStatus.Pending,
    boilerplateCodes: [
    ],
    codingChallengeQuestionOptions: {
      programmingLanguage: 'JavaScript',
      template: 'vanilla-js',
      challengeType: 'Javascript',
      solution: "",
    },
    testCode: "",
    isTestCodeViewable: false,
    testCases: [],
    topics: [],
    dependencies: [
      {
        "name": "@testing-library/react",
        "version": "13.4.0",
        "isDeletable": false
      },
      {
        "name": "jsdom",
        "version": "9.11.0",
        "isDeletable": false
      }
    ],
    domainTitle: '',
    skillTitle: ''
  }

  useEffect(() => {
    mutate();
    mutateSkills();
    mutateDomains();
  }, [mutate, mutateDomains, mutateSkills]);

  if (isLoading || isDomainsLoading || isSkillsLoading) return null;

  const difficultyLevelOptions = [
    { value: 'easiest', label: 'easiest' },
    { value: 'easy', label: 'easy' },
    { value: 'medium', label: 'medium' },
    { value: 'harder', label: 'harder' },
    { value: 'hardest', label: 'hardest' }
  ];

  const templateOptions = [
    { value: "react", label: "react" },
    { value: "vanilla-js", label: "vanilla js" }
  ]

  const assessmentOptions = assesments.map((assessment: any) => {
    return {
      value: assessment.id,
      label: assessment.title
    }
  });

  const skillOptions = skills.map((skill: any) => {
    return {
      value: skill.id,
      label: skill.title
    }
  });

  const domainOptions = domains.map((domain: any) => {
    return {
      value: domain.id,
      label: domain.title
    }
  });

  return (
    <div>
      <h2 className="text-xl mt-2 mb-5 font-medium">Coding challenge question</h2>
      <Formik
        initialValues={{ ...initialCodingChallengesValue }}
        onSubmit={async (values, formikHelper) => {
          values.durationInSeconds = Number(values.durationInSeconds)
          values.testCases?.map((testCase) => {
            testCase.points = Number(testCase.points)
          })

          try {
            const res = await createQuestion(values)
            if (res.status === 200) {
              formikHelper.resetForm();
              setErrors([]);
              setGlobalError("");
              notifySuccess('Coding question is created successfully')
              localStorage.removeItem("mcq-question-payload")
            }

          } catch (error: any) {
            if (error.response.status === 400 && error.response.data.errorMessages.length > 0) {
              setErrors(error.response.data.errorMessages)
            } else {
              setGlobalError("something went wrong")
            }
            console.error(error)
          }
        }}
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            resetForm
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <Card classNames='px-4 py-5'>
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <div className="mb-5">
                      <label htmlFor="title">Title</label>
                      <InputBox
                        type="text"
                        name="title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="assessment">Assessment</label>
                      <SelectField
                        options={assessmentOptions}
                        defaultValue={assessmentOptions.find((option: any) => values.assessment === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("assessment")(selectedOption.value)
                        }}
                        name="assessment"
                        placeholder="select assessment"
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="skill">Skill</label>
                      <SelectField
                        options={skillOptions}
                        defaultValue={skillOptions.find((option: any) => values.skill === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("skill")(selectedOption.value)
                        }}
                        name="skill"
                        placeholder='select skill'
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="domain">Domain</label>
                      <SelectField
                        options={domainOptions}
                        defaultValue={domainOptions.find((option: any) => values.domain === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("domain")(selectedOption.value)
                        }}
                        name="domain"
                        placeholder='select domain'
                      />
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="mb-5">
                      <label htmlFor="duration-in-sec">Duration (second)</label>
                      <InputBox
                        type="number"
                        name="durationInSeconds"
                        id="duration-in-sec"
                        value={values.durationInSeconds}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="diff-level">Template</label>
                      <SelectField
                        options={templateOptions}
                        defaultValue={templateOptions.find(option => values.codingChallengeQuestionOptions.template === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("codingChallengeQuestionOptions.template")(selectedOption.value)
                        }}
                        name="codingChallengeQuestionOptions.template"
                      />
                    </div>


                    <div className="mb-5">
                      <label htmlFor="diff-level">Difficulty level</label>
                      <SelectField
                        options={difficultyLevelOptions}
                        defaultValue={difficultyLevelOptions.find(option => values.difficultyLevel === option.value)}
                        onChange={(selectedOption: any) => {
                          handleChange("difficultyLevel")(selectedOption.value)
                        }}
                        name="difficultyLevel"
                      />
                    </div>


                    {/* create and view tags  */}
                    <h4 className="font-normal">Topics ({values?.topics!.length})</h4>
                    <TopicsField fields={props} />
                    
                  </div>
                </div>
              </Card>

              <div className="col-span-2 mb-14">
                <span className="block mt-5 mb-1">Statement</span>
                <Field name="statement">
                  {({ field }: any) => <TextEditor value={field.value} onChange={field.onChange(field.name)} />}
                </Field>
              </div>

              <Card classNames='px-4 py-5 my-2'>
                <div className="mb-5">
                  <label htmlFor="testCode">Test code</label>
                  <Editor
                    height="20vh"
                    defaultLanguage="javascript"
                    value={values.testCode}
                    onChange={(value) => {
                      setFieldValue('testCode', value)
                    }}
                    className="border rounded md"
                  />
                </div>
              </Card>

              <Card classNames='px-4 py-5 my-2'>
                <div className="mb-5">
                  <label htmlFor="testCode">Solution</label>
                  <Editor
                    height="20vh"
                    defaultLanguage="javascript"
                    value={values.codingChallengeQuestionOptions.solution}
                    onChange={(value) => {
                      setFieldValue('codingChallengeQuestionOptions.solution', value)
                    }}
                    className="border rounded md"
                  />
                </div>
              </Card>


              <Card classNames='px-4 py-5'>
                {/* test cases  */}
                <h4 className="font-medium mb-2">Test cases ({values.testCases!.length}) </h4>
                <FieldArray name="testCases">
                  {({ insert, remove, push }) => (
                    <>
                      <div className="h-[300px] overflow-y-auto">
                        {values.testCases!.length > 0 &&
                          values.testCases!.map((testCase, index) => (
                            <Card classNames="mb-2 px-2 py-3" key={index}>
                              <div className="grid grid-cols-3 gap-2" key={index}>
                                <div className="col">
                                  <InputBox
                                    name={`testCases.${index}.title`}
                                    placeholder="Enter test case title"
                                    type="text"
                                    value={testCase.title}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col text-center">
                                  <InputBox
                                    name={`testCases.${index}.points`}
                                    placeholder="points"
                                    type="number"
                                    value={testCase.points}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col text-right">
                                  <Button
                                    type="button"
                                    className="border rounded-md bg-red-600 text-white px-4 py-1.5"
                                    onClick={() => remove(index)}
                                  >
                                    remove
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          ))}

                      </div>
                      <Button
                        type="button"
                        className="border rounded-md px-3 mt-1 py-1 bg-indigo-600 text-white"
                        onClick={() => push({ value: '', isCorrectAnswer: false })}
                      >
                        + Add test cases
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Card>


              <Card classNames='px-4 py-5 my-2'>
                {/* boilerplate code  */}
                <h4 className="font-medium my-4">Boilerplate ({values.boilerplateCodes!.length})</h4>
                <FieldArray name="boilerplateCodes">
                  {({ insert, remove, push }) => (
                    <>
                      <div className="h-[300px] overflow-y-auto">
                        {values.boilerplateCodes!.length > 0 &&
                          values.boilerplateCodes!.map((boilerplateCode, index) => (
                            <Card classNames="mb-2 px-2 py-3" key={index}>
                              <div className="grid grid-cols-3 gap-2" key={index}>
                                <div className="col">
                                  <InputBox
                                    name={`boilerplateCodes.${index}.fileName`}
                                    placeholder="Enter filename"
                                    type="text"
                                    value={boilerplateCode.fileName}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col text-right">

                                  {!boilerplateCode.code ? (
                                    <Button
                                      type="button"
                                      className="border rounded-md bg-indigo-600 text-white px-4 py-1.5"
                                      onClick={() => {
                                        setCode(boilerplateCode.code)
                                        setIdx(index)
                                        setOpenModal(true)
                                      }}
                                    >
                                      add code
                                    </Button>
                                  ) : (
                                    <Button
                                      type="button"
                                      className="border rounded-md bg-indigo-600 text-white px-4 py-1.5"
                                      onClick={() => {
                                        setCode(boilerplateCode.code)
                                        setIdx(index)
                                        setOpenModal(true)
                                      }}
                                    >
                                      view code
                                    </Button>
                                  )}
                                </div>
                                <div className="col text-right">
                                  <Button
                                    type="button"
                                    className="border rounded-md bg-red-600 text-white px-4 py-1.5"
                                    onClick={() => remove(index)}
                                  >
                                    remove
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          ))}
                        <Modal
                          title={`view boilerplate file`}
                          onClose={() => { }}
                          state={openModal}
                          action={
                            <>
                              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-">
                                <button
                                  className="rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm mr-2"
                                  onClick={() => {
                                    setFieldValue(`boilerplateCodes.${idx}.code`, editorRef.current?.getValue())
                                    setOpenModal(false)
                                  }}
                                >
                                  save
                                </button>
                                <button
                                  form="question-form"
                                  className="rounded-md border border-transparent bg-red-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                  onClick={() => {
                                    setOpenModal(false)
                                    setCode('')
                                    setIdx(0)
                                  }}
                                >
                                  No
                                </button>
                              </div>
                            </>
                          }
                        >
                          <div className="text-sm text-gray-500">
                            <Editor
                              height="40vh"
                              defaultLanguage="javascript"
                              defaultValue={code ? code : "//write your code"}
                              onMount={handleEditorDidMount}
                              className="border rounded md"
                            />
                          </div>
                        </Modal>
                      </div>
                      <Button
                        type="button"
                        className="border rounded-md px-3 mt-1 py-1 bg-indigo-600 text-white"
                        onClick={() => {
                          push({ fileName: "", code: "", extension: "js" })
                        }}
                      >
                        + Add new file
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Card>

              {errors.length > 0 ? (
                <>
                  <Alert variant='error' message={
                    <>
                      <h4 className="font-semibold">Errors</h4>
                      <ul className="list-disc ml-3">
                        {errors.map((error: any) => {
                          return <li>{error}</li>
                        })}
                      </ul>
                    </>
                  } />
                </>
              ) : null}

              {globalError ? <>
                <Alert variant='error' message={
                  <>
                    {globalError}
                  </>
                } />
              </> : null}

              <div className="mt-2">
                <Button type="submit"
                  className={classNames("px-3 py-2 text-white rounded-md cursor-pointer", isSubmitting ? "bg-indigo-200" : "bg-indigo-600")}
                  disabled={isSubmitting}
                  height="10%">
                  <div className="flex">
                    <span>
                      Submit
                    </span>
                    {isSubmitting ?
                      <span className="ml-2 mt-1">
                        <TailSpin
                          height="20"
                          width="20"
                          color="#000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </span>
                      : null}
                  </div>
                </Button>

                <Button type="button" onClick={() => {
                  resetForm();
                  setErrors([])
                  setGlobalError("")
                  localStorage.removeItem("coding-challege-question-payload");
                }} className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer ml-2">
                  Reset Form
                </Button>

                <Button type="button" onClick={() => {
                  setOpen(true)
                }} className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer ml-2">
                  Preview
                </Button>
                <PersistFormikValues name="coding-challege-question-payload" />
              </div>
            </form>
          )
        }}
      </Formik>
      <PreviewQuestion
        state={open}
        onClose={() => {
          setOpen(!open)
        }}
        actions={<>
          <div className="mt-2">
            <Button type="button" className="px-3 py-2 bg-indigo-600 text-white rounded-md cursor-pointer" height="10%" onClick={() =>
              setOpen(false)
            }>
              close
            </Button>
          </div>
        </>}
        previewTitle="Question Preview"
        preview={<>
          <QuestionContext.Provider value={{ question: codingChallengeData, mutate, isLoading: false, questionAnswer: { dependencies: [] }, isLoadingQuestionAnswer: false, mutateQuestionAnswer: () => { } }}>
            {codingChallengeData ? <CodingPattern questionData={codingChallengeData} handleChange={""} values={{ solutions: { "src/index.js": "" } }} /> : <p>Nothing added yet</p>}
          </QuestionContext.Provider>
        </>}
      />
      <Notification />
    </div>
  )
}

export default CreateCodingChallenges