import DashboardContent from "../components/DashboardContent"
import NoDataFound from "../ui/empty-state/NoDataFound"

const Dashboard = () => {
  
    return (
        <>
            <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
            <DashboardContent />
        </>
    )
}

export default Dashboard