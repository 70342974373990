import { CalendarIcon } from '@heroicons/react/20/solid'
import { ClockIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useUserPersonalAssessments } from '../../hooks/userAssessments'
import Badge from '../../ui/Badge'
import NoDataFound from '../../ui/empty-state/NoDataFound'
import Spinner from '../../ui/Spinner'
import { toFixedWithNoRounding } from '../../utils/custom-math'
import { formatDateTime } from '../../utils/datetime-converter'

interface UserAssessmentContentProps {
  status?: string;
}

export default function UserAssessmentContent({ status }: UserAssessmentContentProps) {
  let query: any = {};
  query['status'] = status;
  
  if (status === "all") {
    query = {}
  }

  const { userPersonalAssessments, isLoading, mutate } = useUserPersonalAssessments(query);

  const statusVariant: any = {
    completed: 'success',
    started: 'warning'
  }

  useEffect(() => {
    mutate()
  }, [mutate])

  if (isLoading) return <Spinner />;

  return (
    <div className="overflow-hidden">
      <ul role="list" className="divide-gray-200">
        {
          userPersonalAssessments.length <= 0 ? <NoDataFound variant='border' message='No data found'/>:
          userPersonalAssessments.map((userAssessment: any) => (
            <li key={userAssessment.id} className="bg-white border mt-2 sm:rounded-md">
              <Link to={`/user-assessment/${userAssessment.id}`} className="block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="truncate">
                      <span className="font-medium text-indigo-600 text-xl">
                        {userAssessment?.assessment?.title}
                      </span>
                      <b className="ml-2">({userAssessment?.assessment?.requiredExperience})</b>
                    </div>
                    <div className="ml-2 flex flex-shrink-0">
                      <span className="inline-flex rounded-md bg-green-100 px-2 text-2xl font-semibold leading-5 text-green-800 py-1">
                        {userAssessment.totalScore}/{userAssessment.totalPoints} 
                        <small className="font-normal text-sm ml-0.5 mt-[2.5px]">pts</small>
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <Badge variant={statusVariant[userAssessment?.status]} text={userAssessment?.status === "started"? "running" :userAssessment?.status} showButton={false} />
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <span className="mr-2">
                          Time:
                        </span>
                        <b className="mr-1">{userAssessment?.totalAssessmentTimeInSeconds ? `${userAssessment?.totalAssessmentTimeInSeconds} sec` : "-"}</b>
                      </p>
  
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <CalendarIcon className="h-5 w-5 mr-2" />
                        <span className="mr-2">
                          Started at:
                        </span>
                        <b className="mr-1">{formatDateTime(userAssessment?.startedAt, "MMM D, YYYY, hh:mm:s A")}</b>
                      </p>
  
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <CalendarIcon className="h-5 w-5 mr-2"/>
                        <span className="mr-2">
                          Ended at:
                        </span>
                        <b className="mr-1">{userAssessment?.endedAt ? formatDateTime(userAssessment?.endedAt, "MMM D, YYYY, hh:mm:s A"): "-"}</b>
                      </p>
  
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <p>
                        <span>Completed within</span>
                        {userAssessment?.totalTakenTimeInSeconds ?
                          <Badge classNames="ml-2" variant="info" text={`${toFixedWithNoRounding(userAssessment?.totalTakenTimeInSeconds)} sec`} showButton={false} /> : <b className="ml-1">-</b>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  )
}
