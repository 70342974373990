import { useEffect } from "react";
import { http } from "../services/http";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();

    useEffect(() => {

        const requestIntercept = http.interceptors.request.use(
            config => {
                if (config && config.headers && !config.headers['authorization']) {
                    config.headers['authorization'] = `${localStorage.getItem('accessToken')}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = http.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['authorization'] = `${newAccessToken}`;
                    return http(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            http.interceptors.request.eject(requestIntercept);
            http.interceptors.response.eject(responseIntercept);
        }
    }, [refresh])

    return http;
}

export default useAxiosPrivate;