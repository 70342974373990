import classNames from "classnames"

interface NoDataFoundProps {
  variant?: "border" | "normal";
  message: string;
}
const NoDataFound = ({ variant, message }: NoDataFoundProps) => {
  return (
    <div className={classNames("h-40 w-full flex h-screen justify-center align-middle", variant && variant === "border" ? " border-4 border-dashed border-gray-400 rounded": null)}>
      <div className="m-auto text-xl font-semibold">
        {message}
      </div>
    </div>
  )
}

export default NoDataFound